import React from 'react'
import { Grid, TextField, Button, Select, MenuItem, FormControl, InputLabel} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';

const MainContainer = styled(Grid)`  
  display: flex;
  width: 100%;
  flex-direction:row;
  justify-content:space-between;
  align-items: center;
  // margin-left: -7px;
`;

const DropDownContainer = styled(Grid)`
  display: flex;
  flex:1;
  justify-content:start;
  width:100%;
 `
const BtnContainer = styled(Grid)`
   display: flex;
   width:100%;
   flex:1;
   justify-content:center;
   
`




const SelectFinancialYear = ({values, handleChange, btnLabel}) => {
  return (
    <MainContainer container spacing={2} >
            <DropDownContainer item xs={6} >
                <FormControl fullWidth>
                  <InputLabel id="select-year">Select Financial Year</InputLabel>
                  <Select
                    labelId="select-year"
                    name="financialYear"
                    label="Select Financial Year"
                    value={values.financialYear}
                    onChange={handleChange}
                   
                  >
                    <MenuItem value={values.financialYear}>{values.financialYear}</MenuItem>
                  </Select>
                </FormControl>
            <BtnContainer item xs={6}>
                <Button variant="contained" color="primary"  >{btnLabel}</Button>
            </BtnContainer>
            </DropDownContainer>
    </MainContainer>

  )
}

export default SelectFinancialYear;
