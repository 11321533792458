import React from 'react';
import { Button } from '@mui/material';

const CustomButton = ({ btnLable, onClick, type = 'button', variant = 'contained', color = 'primary', ...rest }) => {
  return (
    <Button
      type={type}
      variant={variant}
      color={color}
      onClick={onClick}
      {...rest}
    >
      {btnLable}
    </Button>
  );
};

export default CustomButton;
