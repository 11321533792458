import React, { useState } from 'react';
import Table from './../../../reuseable/ReusableTable';
import { Grid, Box, Select, MenuItem, FormControl, InputLabel, Container, Paper} from '@mui/material';
import { Formik, Form, Field } from 'formik';

import styled from '@emotion/styled';
import ReusableTable from './../../../reuseable/ReusableTable';


const LayoutContainer = styled(Container)`  
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content:start;
  max-width: 600px;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  adding: 16px;
  top: 12%;
  left:18%;
 
  backgroundColor:'#ccffff';
`;

const DropDownContainer = styled(Grid)`
  display: flex;
  flex:1;
  justify-content:start;
  width:30%;
  margin:16px 8px;

 `




const FinalizeDividend = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)

    const columns = [
        {field:'sno', label:'SNO'},
        {field:'unit', label:'Unit'},
        {field:'NameDesig', label:'NameDesig'},
        {field:'Dividend', label:'Dividend'},
        {field:'TFInterest', label:'TFInterset'},
        {field:'total', label:'Total'},
        {field:'paid', label: 'Paid'},
        {field:'chequeNo', label:'ChequeNo'},
        {field:'year', label:'Year'},
    ]

  return (
    <LayoutContainer component={Paper} elevation={5} >
         
            <Formik
                initialValues={{
                    FinancialYear: '',
                  }}

                onSubmit={(values) => {
                    // Handle form submission
                 }}
            
            >{(values,handleChange)=>(
                <Form>
                    <DropDownContainer item xs={6} >

                    <FormControl fullWidth>
                    <InputLabel field="select-year">Select Financial Year</InputLabel>
                    <Select
                        labelId="select-year"
                        name="financialYear"
                        label="Select Financial Year"
                        value={values.financialYear}
                        onChange={handleChange}
                    >
                        <MenuItem value={values.financialYear}>{values.financialYear}</MenuItem>
                    </Select>
                    </FormControl>
                    </DropDownContainer>
                </Form>
            )}
            
            </Formik>
           

    <Box>
        {
            loading ? (
                <p>Loading...</p>
            ):(<ReusableTable data={data} columns={columns} />)
        }
    </Box>
</LayoutContainer>
  )
}

export default FinalizeDividend
