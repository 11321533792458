import React, {useState} from 'react';
import { Box, Grid, Container, Paper } from '@mui/material';
import { Formik  } from 'formik';
import * as Yup from 'yup';
import CustomButton from '../../reuseable/CustomButton';
import CustomDropDown from '../../reuseable/CustomDropDown';
import CustomTextField from '../../reuseable/CustomTextFeild';
import styled from '@emotion/styled';



const LayoutContainer = styled(Container)`  
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content:start;
  max-width: 600px;
  width: 100%;
  min-height: 60vh;
  height: 70vh;
  adding: 16px;
  top: 12%;
  left:18%;
  marginTop: theme.spacing(2);
  marginBottom: theme.spacing(2);
  
`;

const validationSchema = Yup.object().shape({
    TransactionType: Yup.string().required('Transaction Type is required'),
    DebitAccount:Yup.string().required('Debit Account is required'),
    CreditAccount: Yup.string().required('Credit Account is required'),
    ChequeNo: Yup.string().required('Cheque No is required'),
    Amount: Yup.string().required('Amount is required'),
    VoucherDate:Yup.string().required('Voucher Date is required'),
    remarks: Yup.string(),
});



const CashWithdrawl = () => {
    const [openModal, setOpenModal] = useState(false);
    const [editingMember, setEditingMember] = useState(null);

    const handleSubmit =(value) =>{
        console.log(value)
    }


  return (
    <LayoutContainer component={Paper} elevation={5}>
        <Formik
             initialValues={editingMember || {
                TransactionType: '',
                DebitAccount:'',
                CreditAccount: '',
                ChequeNo: '',
                Amount: '',
                VoucherDate:'',
                remarks: '',
             }}
             validationSchema={validationSchema}
             onSubmit={handleSubmit}    
        
        >{({isSubmitting, values, handleChange, setFieldValue, handleBlur})=>
            <Box sx={{ padding: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <CustomDropDown
                            label="Type of Transaction"
                            name="TransactionType"
                            options={
                                [
                                    { value: 'cash_withdrawal', label: 'Cash Withdrawal' },
                                    { value: 'deposit', label: 'Deposit' },
                                  ]
                            }
                          
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomDropDown
                            label="Debit Account"
                            name="debitAccount"
                            options={[
                                { value: 'cash_on_hand', label: 'Cash On Hand 1' },
                                { value: 'bank_account_2', label: 'Bank Account 2' },
                              ]}
                          
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomDropDown
                            label="Credit Account"
                            name="CreditAccount"
                            options={[
                                { value: 'upcb_ca_1086', label: 'UPCB CA 1086' },
                                { value: 'bank_account_2', label: 'Bank Account 2' },
                              ]}
                          
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomTextField
                        label="Cheque No"
                        name="chequeNo"
                     
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <CustomTextField
                        label="Amount"
                        name="amount"
                       
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <CustomTextField
                    label="Voucher Date"
                    name="voucherDate"
                   
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomTextField
                    label="Remarks"
                    name="remarks"
                   
                    multiline
                    rows={3}
                    />
                </Grid>
                    
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <CustomButton btnLable="Save" type="submit" color="primary" />
                    <CustomButton btnLable="Cancel" color="secondary" sx={{ ml: 2 }} />
                    <CustomButton btnLable="Close" color="error" sx={{ ml: 2 }} />
                </Box>
            </Box>
        }

        </Formik>
     
    </LayoutContainer>
  )
}

export default CashWithdrawl;
