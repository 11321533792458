import { Password } from '@mui/icons-material';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


const initialState ={
    user:null,
    isAuthenticated:false,
    loading:false,
    error:null,
    token:null,
}

export const login = createAsyncThunk(
    'auth/login',
    async({UserName, Password}, thunkAPI) =>{
        try{
            const response = await axios.post('http://103.166.13.57/society/Admin/Home/Login',{
                UserName,
                Password     
            }, {
                headers:{
                    'Content-Type':'application/json',
                },
            });
            const { token } = response.data;
            localStorage.setItem('token', token);
            return response.data;
        }catch(error){
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);




const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers:{
        loginSuccess:(state,action)=>{
            state.user =action.payload.user;
            state.isAuthenticated = true;
            state.loading = false;
            state.error = null;
            state.token = action.payload.token;
            sessionStorage.setItem('token')
        },
        loginFailed:(state, action)=>{
            state.user = null;
            state.isAuthenticated = false;
            state.loading = false;
            state.error = action.payload;
            state.token = null;
            sessionStorage.removeItem('token')
        },
        logout:(state)=>{
            state.user = null;
            state.isAuthenticated = false;
            state.token = null;
            sessionStorage.removeItem('token');
        }
    }
})

export const {loginSuccess, loginFailed, logout} = authSlice.actions;
export default authSlice.reducer;