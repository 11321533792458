import { createTheme } from "@mui/material";

export const theme = createTheme({
    typography:{
        h1:{fontWeight:'bolder'},
        h2:{
          fontSize:'2rem',
          color:'black',
        },
        h3:{
          fontSize:'1.8rem',
          fontWeight:'bold',
          color:'#090945'
        },
        h4:{
          fontSize:'1.4rem',
          fontWeight:'lighter',
          color:'#7b1fa2'
        },
        h5:{
          fontSize:'1.2rem',
          fontWeight:'lighter',
          color:'#7b1fa2'
        },
        h6:{
          fontSize:'0.8rem',
          fontWeight:'400',
          color:'#03a9f4'
        }
      },
    palette:{
        primary:{
            main:"#1976d2",
            light:"#42a5f5",
            dark:'#1565c0'
        },
        secondary:{
            main:"#9c27b0",
            light:"#ba68c8"
        },
        error:{
          main:'#d32f2f',
          light:'#ef5350'
        },
        warning:{
          main:'#ed6c02',
          light:'#ff9800'
        },
        info:{
          main:"#0288d1",
          light:'#03a9f4'
        },
        success:{
          main:'#2e7d32',
          light:'#4caf50'
        },
        other:{
            main:"#1b5e20"
        }
    },
    spacing: 8,
})