import React from 'react';
// import {Route, Redirect} from 'react-router-dom';
import {Navigate, Outlet } from 'react-router-dom';

// const PrivateRoute = ({component:Component, ...rest}) => {
//     const token = localStorage.getItem('token');


// //   return (
// //     <Route
// //         {...rest}
// //         render = {(props)=>
// //             token ? (
// //                 <Component {...props}/>
// //             ):(
// //                 <Redirect to="/login" />
// //             )


// //         }

// //     />
      
    
// //   )
// }



const PrivateRoute = () => {
    const token = localStorage.getItem('token');
    return  token ? <Outlet /> : <Navigate to="/login" />

}
export default PrivateRoute;
