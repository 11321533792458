import React from 'react';
import * as Yup from 'yup';
import { Box, Button, CardContent, Container, Grid, Paper, TextField, Typography, Card } from '@mui/material';
import { Field, Formik, Form } from 'formik';
import DebitCreditTable from './../../reuseable/DebitCreditTable';
import styled from '@emotion/styled';

const LayoutContainer = styled(Container)`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: start;
//   max-width: 1600px;
  width: 100%;
//   min-height: 100vh;
//   height: 100vh;
  padding: 16px;
 top: 12%;
  left:18%;
//   background-color: #ccffff;
`;

const RowStyle = styled(Grid)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 8px;
  padding: 8px;
`;

const ColumnStyle = styled(Grid)`
  display: flex;
  width: 30%;
  flex-direction: column;
  margin: 8px;
  padding: 8px;
`;

const SectionStyle = styled(Box)`
  margin-top: 8px;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
`;

const TableName = styled(Typography)`
  margin: 1px;
  padding: 1px;
  display: flex;
  justify-content: start;
`;

const validationSchema = Yup.object({
  transactionId: Yup.string().required('Required'),
  voucherNo: Yup.string().required('Required'),
  transactionDate: Yup.date().required('Required'),
  transactionCode: Yup.string().required('Required'),
  transactionType: Yup.string().required('Required'),
  mode: Yup.string().required('Required'),
  totalAmount: Yup.number().required('Required'),
  cashChequeAmount: Yup.number().required('Required'),
  chequeNo: Yup.string().required('Required'),
  bankName: Yup.string().required('Required'),
  remarks: Yup.string().required('Required'),
  staffId: Yup.string().required('Required'),
  staffName: Yup.string().required('Required'),
});

const initialValues = {
  transactionId: '',
  voucherNo: '',
  transactionDate: '',
  transactionCode: '',
  transactionType: '',
  mode: '',
  totalAmount: '',
  chequeAmount: '',
  chequeNo: '',
  bankName: '',
  remarks: '',
  staffId: '',
  staffName: '',
  debitHeads: [{ id: '', account: '', amount: '' }],
  creditHeads: [{ id: '', account: '', amount: '' }],
};

const columns = [
  { id: 'sno', label: 'SNO' },
  { id: 'id', label: 'ID' },
  { id: 'account', label: 'Account' },
  { id: 'amount', label: 'Amount' },
  { id: 'id', label: 'ID' },
];

const JournalVoucher = () => {
  return (
    <LayoutContainer>
        <Card>
           <CardContent>
            <Typography variant="h5" component="h2" gutterBottom>
            Journal Vouchers
            </Typography>
            <Box>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                console.log(values);
                }}
            >
                {({ errors, touched, values }) => (
                <Form>
                    <Grid container spacing={2}>
                    <RowStyle item xs={12}>
                        <ColumnStyle item xs={4}>
                        <Field
                            as={TextField}
                            name="transactionId"
                            type="text"
                            label="Transaction ID"
                            value={values.transactionId}
                            margin="normal"
                            error={touched.transactionId && Boolean(errors.transactionId)}
                            helperText={touched.transactionId && errors.transactionId}
                            fullWidth
                        />
                        <Field
                            as={TextField}
                            name="voucherNo"
                            type="text"
                            label="Voucher No"
                            value={values.voucherNo}
                            margin="normal"
                            error={touched.voucherNo && Boolean(errors.voucherNo)}
                            helperText={touched.voucherNo && errors.voucherNo}
                            fullWidth
                        />
                        <Field
                            as={TextField}
                            name="transactionDate"
                            label="Transaction Date"
                            value={values.transactionDate}
                            type="text"
                            margin="normal"
                            error={touched.transactionDate && Boolean(errors.transactionDate)}
                            helperText={touched.transactionDate && errors.transactionDate}
                            fullWidth
                        />
                        <Field
                            as={TextField}
                            name="transactionCode"
                            label="Transaction Code"
                            value={values.transactionCode}
                            type="text"
                            margin="normal"
                            error={touched.transactionCode && Boolean(errors.transactionCode)}
                            helperText={touched.transactionCode && errors.transactionCode}
                            fullWidth
                        />
                        <Field
                            as={TextField}
                            name="transactionType"
                            label="Transaction Type"
                            value={values.transactionType}
                            type="text"
                            margin="normal"
                            error={touched.transactionType && Boolean(errors.transactionType)}
                            helperText={touched.transactionType && errors.transactionType}
                            fullWidth
                        />
                        </ColumnStyle>
                        <ColumnStyle item xs={4}>
                        <Field
                            as={TextField}
                            name="mode"
                            label="Mode"
                            value={values.mode}
                            margin="normal"
                            error={touched.mode && Boolean(errors.mode)}
                            helperText={touched.mode && errors.mode}
                            fullWidth
                        />
                        <Field
                            as={TextField}
                            name="totalAmount"
                            label="Total Amount"
                            value={values.totalAmount}
                            margin="normal"
                            error={touched.totalAmount && Boolean(errors.totalAmount)}
                            helperText={touched.totalAmount && errors.totalAmount}
                            fullWidth
                        />
                        <Field
                            as={TextField}
                            name="chequeAmount"
                            label="Cash/Cheque Amount"
                            value={values.chequeAmount}
                            type="number"
                            margin="normal"
                            error={touched.chequeAmount && Boolean(errors.chequeAmount)}
                            helperText={touched.chequeAmount && errors.chequeAmount}
                            fullWidth
                        />
                        <Field
                            as={TextField}
                            name="chequeNo"
                            label="Cheque No"
                            value={values.chequeNo}
                            type="text"
                            margin="normal"
                            error={touched.chequeNo && Boolean(errors.chequeNo)}
                            helperText={touched.chequeNo && errors.chequeNo}
                            fullWidth
                        />
                        </ColumnStyle>
                        <ColumnStyle item xs={4}>
                        <Field
                            as={TextField}
                            name="bankName"
                            label="Bank Name"
                            value={values.bankName}
                            type="text"
                            margin="normal"
                            error={touched.bankName && Boolean(errors.bankName)}
                            helperText={touched.bankName && errors.bankName}
                            fullWidth
                        />
                        <Field
                            as={TextField}
                            name="remarks"
                            label="Remarks"
                            value={values.remarks}
                            type="text"
                            margin="normal"
                            error={touched.remarks && Boolean(errors.remarks)}
                            helperText={touched.remarks && errors.remarks}
                            fullWidth
                        />
                        <Field
                            as={TextField}
                            name="staffId"
                            label="Member/Staff ID"
                            value={values.staffId}
                            type="text"
                            margin="normal"
                            error={touched.staffId && Boolean(errors.staffId)}
                            helperText={touched.staffId && errors.staffId}
                            fullWidth
                        />
                        <Field
                            as={TextField}
                            name="staffName"
                            label="Member/Staff Name"
                            value={values.staffName}
                            type="text"
                            margin="normal"
                            error={touched.staffName && Boolean(errors.staffName)}
                            helperText={touched.staffName && errors.staffName}
                            fullWidth
                        />
                        </ColumnStyle>
                    </RowStyle>
                    </Grid>
                    <Box>
                    <SectionStyle component={Paper}>
                        <TableName component="h4">Debit Heads</TableName>
                        <DebitCreditTable name="debitHeads" title="Debit Heads" columns={columns} />
                    </SectionStyle>
                    <SectionStyle component={Paper}>
                        <TableName component="h4">Credit Heads</TableName>
                        <DebitCreditTable name="creditHeads" title="Credit Heads" columns={columns} />
                    </SectionStyle>
                    </Box>
                    <Button type="submit" variant="contained" color="primary" sx={{ mt: 2, mr: 1 }}>
                    Save
                    </Button>
                    <Button type="button" variant="contained" color="secondary" sx={{ mt: 2 }}>
                    Cancel
                    </Button>
                </Form>
                )}
            </Formik>
            </Box>
            </CardContent>
        </Card>
    </LayoutContainer>
  );
};

export default JournalVoucher;
