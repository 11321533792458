import React, { useState } from 'react';
import {
    TextField, Button, Typography, Container, Box, Checkbox,
    Grid, FormControlLabel, MenuItem, Select, InputLabel, FormControl
} from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';

const RenderFormComponent = ({ isSubmitting, values, handleChange, handleBlur, setFieldValue }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [signaturePreview, setSignaturePreview] = useState(null);

  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0];
    setFieldValue('Image', file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSignatureChange = (event) => {
    const file = event.currentTarget.files[0];
    setFieldValue('Signature', file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSignaturePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
    return (
        <Form>
            <Box p={2}>
                <Grid container spacing={2}>
                    {/* Left Column */}
                    <Grid item xs={6}>
                        {[
                            { name: "SNO", label: "S NO" },
                            { name: "MemberID", label: "Member ID" },
                            { name: "PayStaffNo", label: "Pay Staff No" },
                            { name: "HRMSNo", label: "HRMS No" },
                            { name: "AppFormNo", label: "App Form No" },
                            { name: "Title", label: "Title" },
                            // {
                            //     name: "Title",
                            //     label: "Title",
                            //     component: (
                            //         <FormControl fullWidth  variant="standard" margin="normal">
                            //             <InputLabel>Title</InputLabel>
                            //             <Select
                            //                 name="Title"
                            //                 value={values.Title}
                            //                 onChange={handleChange}
                            //                 onBlur={handleBlur}
                            //                 fullWidth                                            
                            //             >
                            //                 <MenuItem value="Mr">Mr</MenuItem>
                            //                 <MenuItem value="Mrs">Mrs</MenuItem>
                            //                 <MenuItem value="Miss">Miss</MenuItem>
                            //             </Select>
                            //         </FormControl>
                            //     )
                            // },
                            { name: "FirstName", label: "First Name" },
                            { name: "LastName", label: "Last Name" },
                            { name: "FatherName", label: "Father Name" },
                            { name: "Unit", label: "Unit" },
                            { name: "SubUnit", label: "Sub Unit" },
                            { name: "Designation", label: "Designation" },
                            { name: "BankName", label: "Bank Name" },
                            { name: "BankAcNo", label: "Bank A/c No" },
                            { name: "Thriftfund", label: "Thrift fund" },
                            { name: "EntranceDate", label: "Entrance Date", type: "date" },
                            { name: "AppointmentDate", label: "Appointment Date", type: "date" },
                            { name: "ResignationDate", label: "Resignation Date", type: "date" },
                            { name: "RetirementDate", label: "Retirement Date", type: "date" },
                            { name: "dateOfBirth", label: "Date Of Birth", type: "date" },
                            { name: "EntryDate", label: "Entry Date", type: "date" },
                            { name: "UserEntry", label: "User Entry" },
                            { name: "UserCancel", label: "User Cancel" },
                            { name: "CancelDate", label: "Cancel Date", type: "date" },
                            { name: "UserEdit", label: "User Edit" },
                            { name: "EditDate", label: "Edit Date", type: "date" },
                            { name: "Remarks", label: "Remarks" },
                            { name: "CircleName", label: "Circle Name" },
                            { name: "C_add1", label: "Correspondence Address1" },
                            { name: "C_add2", label: "Correspondence Address2" },
                            { name: "C_add3", label: "Correspondence Address3" },
                           
                        ].map(({ name, label, type = "text", component }) => (
                            <Grid item key={name} xs={12}>
                                {component ? component : (
                                    <Field
                                        as={TextField}
                                        name={name}
                                        label={label}
                                        type={type}
                                        value={values[name]}
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={type === "date" ? { shrink: true } : {}}
                                    />
                                )}
                                <ErrorMessage name={name} component="div" className="error" />
                            </Grid>
                        ))}
                       <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Field
                                        name="CertifiedByBSNL"
                                        type="checkbox"
                                        as={Checkbox}
                                        checked={values.CertifiedByBSNL}
                                        onChange={() => setFieldValue("CertifiedByBSNL", !values.CertifiedByBSNL)}
                                        sx={{display:'flex', justifyContent:'start'}}
                                    />
                                }
                                label="Certified By BSNL"
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                    </Grid>

                    {/* Right Column */}
                    <Grid item xs={6}>
                        {[
                            { name: "C_City", label: "Correspondence City" },
                            { name: "C_State", label: "Correspondence State" },
                            { name: "C_Pin", label: "Correspondence Pin" },
                            { name: "O_add1", label: "Office Address" },
                            { name: "O_add2", label: "Office Address 2" },
                            { name: "O_add3", label: "Office Address 3" },
                            { name: "O_City", label: "Office City" },
                            { name: "O_State", label: "Office State" },
                            { name: "O_Pin", label: "Office Pin" },
                            { name: "P_add1", label: "Permanent Address" },
                            { name: "P_add2", label: "Permanent Address 2" },
                            { name: "P_add3", label: "Permanent Address 3" },
                            { name: "P_City", label: "Permanent City" },
                            { name: "P_State", label: "Permanent State" },
                            { name: "P_Pin", label: "Permanent Pin" },
                            { name: "HomePhone", label: "Home Phone" },
                            { name: "OfficePhone", label: "Office Phone" },
                            { name: "MobileNo", label: "Mobile No" },
                            { name: "EmailID", label: "Email ID" },
                            { name: "EmergencyPhone", label: "Emergency Phone" },
                            { name: "AddressUserEdit", label: "Address User Edit" },
                            { name: "NomineeName", label: "Nominee Name" },
                            { name: "Add1", label: "Address" },
                            { name: "Add2", label: "Address 2" },
                            { name: "Add3", label: "Address 3" },
                            { name: "City", label: "City" },
                            { name: "State", label: "State" },
                            { name: "Pin", label: "Pin" },
                            { name: "PhoneNo", label: "Phone No" },
                            { name: "Relation", label: "Relation" },
                            { name: "Age", label: "Age" },
                            
                        ].map(({ name, label, type = "text" }) => (
                            <Grid item key={name} xs={12}>
                                <Field
                                    as={TextField}
                                    name={name}
                                    label={label}
                                    type={type}
                                    value={values[name]}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={type === "date" ? { shrink: true } : {}}
                                />
                                <ErrorMessage name={name} component="div" className="error" />
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="IsMinor"
                                        checked={values.IsMinor}
                                        onChange={() => setFieldValue("IsMinor", !values.IsMinor)}
                                       
                                    />
                                }
                                label="Is Minor"
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        {values.IsMinor === true  && (
                            <>
                                <Grid item xs={6}>
                                    <Field
                                        as={TextField}
                                        name="DOB"
                                        label="Date Of Birth"
                                        type='date'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={values.DOB}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <ErrorMessage name="DOB" component="div" className="error" />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        as={TextField}
                                        name="Percentage"
                                        label="Percentage"
                                        value={values.Percentage}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <ErrorMessage name="Percentage" component="div" className="error" />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        as={TextField}
                                        name="G_Name"
                                        label="Guardian Name"
                                        value={values.G_Name}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <ErrorMessage name="G_Name" component="div" className="error" />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        as={TextField}
                                        name="G_Relation"
                                        label="Guardian Relation"
                                        value={values.G_Relation}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <ErrorMessage name="G_Relation" component="div" className="error" />
                                </Grid>
                            </>
                        )}
                         <Grid item xs={6}>
                                    <Field
                                        as={TextField}
                                        name="NomineeRemarks"
                                        label="Nominee Remarks"
                                        value={values.NomineeRemarks}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <ErrorMessage name="G_Name" component="div" className="error" />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        as={TextField}
                                        name="NomineeUserEdit"
                                        label="Nominee User Edit"
                                        value={values.NomineeUserEdit}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <ErrorMessage name="Nominee User Edit" component="div" className="error" />
                                </Grid>    
                            <Grid item xs={6}>
                                <Field
                                    as={TextField}
                                    name="Image"
                                    label="Image"
                                    type="file"
                                    fullWidth
                                    margin="normal"
                                    value={values.Image}
                                    variant="filled"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChange}
                                />
                        <ErrorMessage name="Image" component="div" className="error" />
                        </Grid>
                        <Grid item xs={6}>
                        <Field
                            as={TextField}
                            name="Signature"
                            label="Signature"
                            type="file"
                            fullWidth
                            value={values.Signature}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="filled"
                            onChange={handleChange}
                        />
                    <ErrorMessage name="Signature" component="div" className="error" />
             </Grid>
                </Grid>
                <Box mt={2}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                    >
                        Submit
                    </Button>
                </Box>
            </Box>
        </Form>
    )
}

export default RenderFormComponent;
