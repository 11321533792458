import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, CircularProgress, Modal, Box } from '@mui/material';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';

const LayoutContainer = styled(Container)`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: start;
//   max-width: 1600px;
  width: 100%;
//   min-height: 100vh;
//   height: 100vh;
  padding: 16px;
  top: 12%;
  left:18%;
//   background-color: #ccffff;
`;

const ADDBtn = styled(Button)`
  display:flex; 
  justify-content: center; 
  margin-bottom: 16px; 
  width: 15%;
  align-items:center; 
  padding:8px;
`;

const TableStyle= styled(Table)`
  width:100%;
  min-width:650;
`;
const ModalStyle= styled(Box)`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 400;
      background-color: white;
      border: 2px solid #000;
      boxShadow: 24;
      padding: 4;
`;


const validationSchema = Yup.object().shape({
  value: Yup.string().required('Value is required'),
});


const API_URL = 'https://tecslkoupe.com'
const token = localStorage.getItem('token')

const OfficeComponent = () => {

 
  const [officeData, setOfficeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedSetting, setSelectedSetting] = useState(null);

  useEffect(() => {
    fetchOfficeData();
  }, []);

  const fetchOfficeData = async () => {
    setLoading(true);
    try {
        const response = await axios.post(`${API_URL}/Admin/Master/OfficeInformation`,{
            "Action": "Get"
          },{
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
    );
    setOfficeData(response.data);
      setLoading(false);
    } catch (error) {
      setError('Failed to fetch settings');
      setLoading(false);
    }
  };

  const handleUpdate = async (values) => {
    try {
        await axios.post(`${API_URL}/Admin/Master/OfficeInformation`,  
            { ...values,"Action": "Update" , UserID: officeData.UserID }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      fetchOfficeData();
      setOpenModal(false);
    } catch (error) {
      setError('Failed to update setting');
    }
  };

  const handleOpenModal = (setting) => {
    setSelectedSetting(setting);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedSetting(null);
  };

  return (
    <LayoutContainer >
      <Typography variant="h4" gutterBottom>
        Office Information
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <TableContainer component={Paper}>
          <TableStyle >
            <TableHead>
              <TableRow>
                <TableCell>Key</TableCell>
                <TableCell align="right">Value</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(officeData).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {key}
                  </TableCell>
                  <TableCell align="right">{value}</TableCell>
                  <TableCell align="right">
                    <Button variant="contained" color="primary" onClick={() => handleOpenModal({ key, value })}>
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TableStyle>
        </TableContainer>
      )}
      <Modal open={openModal} onClose={handleCloseModal}>
        <ModalStyle >
          <Typography variant="h6" gutterBottom>
            Edit Setting
          </Typography>
          <Formik
            initialValues={{ value: selectedSetting ? selectedSetting.value : '' }}
            validationSchema={validationSchema}
            onSubmit={handleUpdate}
          >
            {({ errors, touched }) => (
              <Form>
                <Field name="value">
                  {({ field }) => (
                    <TextField
                      {...field}
                      label="Value"
                      variant="outlined"
                      fullWidth
                      sx={{ marginBottom: '16px'}}
                      error={touched.value && !!errors.value}
                      helperText={touched.value && errors.value}
                    />
                  )}
                </Field>
                <Button type="submit" variant="contained" color="primary">
                  Save
                </Button>
                <Button onClick={handleCloseModal} variant="contained" color="secondary" style={{ marginLeft: 8 }}>
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </ModalStyle>
      </Modal>
    </LayoutContainer>
  );
};

export default OfficeComponent;
