import React, { useState } from 'react';
import { Box, Grid, Container, Paper } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';

import CustomButton from '../../reuseable/CustomButton';
import CustomDropDown from '../../reuseable/CustomDropDown';
import CustomTextField from '../../reuseable/CustomTextFeild';
import ReusableTable from '../../reuseable/ReusableTable';
import { Balance, Circle } from '@mui/icons-material';
import DebitCreditTable from '../../reuseable/DebitCreditTable';


const LayoutContainer = styled(Container)`  
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content:start;
  max-width: 600px;
  width: 100%;
  // min-height: 120vh;
  // height: 120vh;
  adding: 16px;
  top: 12%;
  left:18%;
  marginTop: theme.spacing(2);
  marginBottom: theme.spacing(2);
  
`;

const validationSchema = Yup.object().shape({
  TotalCr: Yup.number().required('Total  Type is required'),
  TotalDr:Yup.number().required('Total Account is required'),
  Balance: Yup.number().required('Balance Account is required'),
  Unit: Yup.string().required('Cheque No is required'),
  RecoveryList: Yup.string().required('Amount is required'),
  Circle:Yup.string().required('Voucher Date is required'),
  TotalAmount:Yup.number(),
  VoucherDate:Yup.string().required('Voucher Date is required'),
  remarks: Yup.string(),
});


const data =[
    { id: 1, transactionType: 'Receipt', credit: 11498966, debit: 0, voucherNo: '0-0-F-34-97', receiptNo: '0-0-F-34-97', listNo: '...' },
    { id: 2, transactionType: 'Receipt', credit: 11498966, debit: 0, voucherNo: '0-0-F-34-97', receiptNo: '0-0-F-34-97', listNo: '...' },
  
  ];
  
 

const ClearSuspense = () => {
  // const [data, setData] = useState([]);

  const handleSubmit = (values) => {
    console.log(values);
  };


  const columns = [
    { field: 'id', headerName: 'SNO', width: 90 },
    { field: 'Tdate', headerName: 'TDate', width: 150 },
    { field: 'transactionType', headerName: 'Transaction Type', width: 150 },
    { field: 'credit', headerName: 'Credit', width: 110 },
    { field: 'debit', headerName: 'Debit', width: 110 },
    { field: 'voucherNo', headerName: 'Voucher No', width: 150 },
    { field: 'receiptNo', headerName: 'Receipt No', width: 150 },
    { field: 'listNo', headerName: 'List No', width: 150 },
  ];


  const Debitcolumns = [
    { id: 'sno', label: 'SNO', name: 'SNO' },
    { id: 'id', label: 'ID',  name: 'ID' },
    { id: 'account', label: 'Account', name: 'Account' },
    { id: 'amount', label: 'Amount',  name: 'Amount' },
    { id: 'id', label: 'ID', name: 'ID' },
  ];



  return (
    <LayoutContainer component={Paper} elevation={5}>
      <Formik
        initialValues={{
          TotalCr:'',
          TotalDr:'',
          Balance:'',
          Unit:'',
          RecoveryList:'',
          Circle:'',
          Month:'',
          Year:'',
          TotalAmount:'',
          VoucherDate:'',
          remarks:'',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({isSubmitting, values, handleChange, setFieldValue, handleBlur})=>(
            <Box sx={{padding:2}}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ReusableTable columns={columns} data={data} />
                </Grid>
                <Grid item xs={4}>
                    <CustomTextField label="Total Cr" name="totalCr" />
                </Grid>
                <Grid item xs={4}>
                    <CustomTextField label="Total Dr" name="totalDr" />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField label="Balance" name="balance" />
                </Grid>
                <Grid item xs={4}>
                <CustomDropDown
                  label="Unit"
                  name="unit"
                  options={[{ value: 'unit1', label: 'Unit 1' }, { value: 'unit2', label: 'Unit 2' }]}
                />
                </Grid>
                <Grid item xs={4}>
                <CustomDropDown
                  label="Circle"
                  name="Circle"
                  options={[{ value: 'circle1', label: 'Circle 1' }, { value: 'circle2', label: 'Circle 2' }]}
                />
                </Grid>
                <Grid item xs={4}>
                    <CustomTextField label="Recovery List" name="recoveryList" />
                </Grid>
                <Grid item xs={4}>
                    <CustomTextField label="Month" name="month" />
                </Grid>
                <Grid item xs={4}>
                    <CustomTextField label="Year" name="year" />
                </Grid>
                <Grid item xs={12}>
                 {/* <DebitCreditTable name="debitHeads" title="Debit Heads" columns={Debitcolumns} /> */}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomButton btnLable="Add to Grid" color="primary" />
                    <CustomButton btnLable="Remove from Grid" color="secondary" sx={{ ml: 2 }} />
                </Grid>
                <Grid item xs={12}>
                   <CustomTextField label="Total Amount" name="totalAmount" />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextField label="Voucher Date" name="voucherDate" />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextField label="Remarks" name="remarks" multiline rows={3} />
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                  <CustomButton btnLable="Save" type="submit" color="primary" />
                  <CustomButton btnLable="Cancel" color="secondary" sx={{ ml: 2 }} />
                  <CustomButton btnLable="Close" color="error" sx={{ ml: 2 }} />
                </Box>
              </Grid>
            </Box>
        )}
      </Formik>
    </LayoutContainer>
  )
}

export default ClearSuspense
