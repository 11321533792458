import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@mui/material';
import React from 'react';

import EditIcon from '@mui/icons-material/Edit';


const ReusableTable = ({ data, columns, onEdit }) => (
    
  <TableContainer >
    <Table>
      <TableHead>
        <TableRow  sx={{display:'flex', flexDirection:'row', backgroundColor:'#1565c0',color:'white' ,minWidth:'200px', justifyContent:'start'}}>
          {columns.map((column) => (
            <TableCell key={column.field} sx={{display:'flex', flexDirection:'column',color:'white' ,minWidth:'200px', justifyContent:'start'}} >{column.headerName}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row) => (
          <TableRow key={row.RowNumber} sx={{display:'flex', flexDirection:'row',minWidth:'200px', justifyContent:'start'}} >
            {columns.map((column) => (
              <TableCell key={column.field} sx={{display:'flex', flexDirection:'row',minWidth:'200px', justifyContent:'start', alignItems:'center' }}>
                {row[column.field]}
              </TableCell>
            ))}  
              <TableCell>
              <IconButton onClick={() => onEdit(row.RowNumber)} variant="contained" color="primary" size="small" >
                <EditIcon />
              </IconButton>
              </TableCell> 
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default ReusableTable;
  


