import React, { useState } from 'react';
import {useDispatch} from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import {TextField, Button, Grid,  Box, Divider,Checkbox,
  FormControlLabel, 
  Typography,
  Card,
  CardHeader,
  CardContent,
  InputAdornment,
  IconButton,
  CircularProgress
} from '@mui/material';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import  axios  from 'axios';
import { loginSuccess, loginFailed } from '../redux/authSlice';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import styled from '@emotion/styled';

const LayoutContainer = styled(Box)`
   position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  // padding: 16px;
  // background-color:  #8b0ec4;
  top: 3%;
  left: 40%;
`;

const StyledCard = styled(Card)`
  max-width: 400px;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  padding: 24px;
`;

const Header = styled(CardHeader)`
  text-align: center;
  margin-bottom: 16px;
  color: blueviolet;
`;

const FormStyled = styled(Form)`
  width: 100%;
`;

const SubmitBtn = styled(Button)`
  margin-top: 16px;
  background-color: primary;
  color: #fff;
  &:hover {
    background-color: #303f9f;
  }
`;

const Linked = styled(Grid)`
      textDecoration: 'none',
      color: 'inherit',
`;


const initialValues = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      agree: false,
    }

 const validationSchema = Yup.object().shape({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      password: Yup.string().required('Password is required'),
      confirmPassword: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
      agree: Yup.bool().oneOf([true], 'You must accept the terms and conditions'),
 })

const Signup = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);

  const [showPassword, setShowPassword] =  useState(false)
  const [comfshowPassword, setcomfshowPassword] =  useState(false)

  const handleClickShowPassword = () =>{
    setShowPassword(!showPassword) 
  }
 
  const handleClickConfShowPassword = () =>{
    setcomfshowPassword(!comfshowPassword) 
  }
 

  const handleSubmit = async(values, {setSubmitting})=>{
    try{
      const response = await axios.post('url', values);
      const {user, token} = response.data;
      dispatch(loginSuccess({user, token}));
      sessionStorage.setItem('token', token);
      navigate('/login')
    }catch(error){
      dispatch(loginFailed(error.message))
    }
    setSubmitting(false);
  }
  return(
    <LayoutContainer  >
      <StyledCard >
      <Header
          title="Sign up with Email address"
          titleTypographyProps={{ variant: 'h5', component: 'h2' }}
        />

        <Divider  />

        <CardContent >

          <Formik 
             initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}          
          >
        {({isSubmitting, values, touched, errors })=>(          
          <FormStyled >    
            <Grid container spacing={2}>
              <Grid  item xs={12} sm={6}>
                <Field
                    as={TextField}
                    label="First Name"
                    name="firstName"
                    type="text"
                    value={values.firstName}
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    fullWidth
                    margin="normal"
                  />   
                </Grid>
                  <Grid item xs={12} sm={6}>
                  <Field
                      fullWidth
                      as={TextField}
                      label="Last Name"
                      name="lastName"
                      value={values.lastName}
                      type="text"
                      error={touched.firstName && Boolean(errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                      margin="normal"
                    /> 
                  </Grid>
              </Grid>
              <Grid item xs={12}>
                <Field 
                  fullWidth
                  as={TextField}
                  type="email"
                  name="email"
                  label="Email"
                  variant="outlined" 
                  value={values.email}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                   margin="normal"
                />
               </Grid>
               <Grid item xs={12}>
                <Field 
                  fullWidth
                  as={TextField}
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={values.password}
                  variant="outlined" 
                   margin="normal"
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    endAdornment:(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}                 
                />
               </Grid>
               <Grid item xs={12}>
                  <Field 
                    fullWidth
                    as={TextField}
                    name="confirmPassword"
                    label="Confirm Password"
                    type={comfshowPassword ? 'text' : 'password'}
                    value={values.confirmPassword}
                    variant="outlined" 
                    margin="normal"
                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    InputProps={{
                      endAdornment:(
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickConfShowPassword}
                            edge="end"
                          >
                            {comfshowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}               
                  />
               
              <Grid item xs={12} >
                <FormControlLabel
                  control={
                    <Checkbox checked={checked} onChange={(event) => setChecked(event.target.checked)} name="checked" color="primary" />
                  }
                  label={
                    <Typography variant="subtitle1">
                      Agree with &nbsp;
                      <Typography variant="subtitle1" component={Link} to="#">
                        Terms & Condition.
                      </Typography>
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                  <SubmitBtn
                    disableElevation 
                    disabled={isSubmitting} 
                    size="large" 
                    type="submit" 
                    variant="contained" 
                    fullWidth
                    margin="normal"
                    
                  >
                      {isSubmitting ? <CircularProgress size={24} /> : 'SignUp'}
                  </SubmitBtn>
              </Grid>
              <Linked item xs={12} >
                Already have an account? 
                <Link to='/' className="register-login">Log in</Link>
              </Linked>
            </Grid>
          </FormStyled>)}
         
          </Formik>
          </CardContent>
        </StyledCard>
    </LayoutContainer>
  )
}

export default Signup;
