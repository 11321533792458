import React from 'react';
import { Formik, Form  } from 'formik';
import  * as Yup from 'yup';
import PersonalDetails from './personalDetails';
import NomineeDetails from './nominee';
import DatesForm from './dates';
import Address from './address';
import IDNumbers from './idNumbers';
// import UnitMemberShip from './unit';
import { Container, Grid, Button, Box, Card, CardContent } from '@mui/material';

//icon
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import MemberShip from './membership';
import RenderFormComponent from './../../home/MemberForm/renderFormComponent'


import styled from '@emotion/styled';

const LayoutContainer = styled(Container)`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: start;
//   max-width: 1600px;
  width: 100%;
  // min-height: 0vh;
  // height: 40vh;
  padding: 16px;
  top: 12%;
  left:18%;
  // background-color: #ccffff;
`;

const validationSchema = Yup.object().shape({
  // ID number 
  memberId: Yup.string().required('Member ID is required'),
  payStaffNo: Yup.string().required('Pay Staff No is required'),
  hrmsNo: Yup.string().required('HRMS No is required'),
  appFormNo: Yup.string().required('App Form No is required'),

  //Person
  title: Yup.string().required('Title is required'),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  designation: Yup.string().required('Designation is required'),
  fatherName: Yup.string().required("Father's Name is required"),
  bankName: Yup.string().required('Bank Name is required'),
  bankAcNo: Yup.string().required('Bank Account Number is required'),
  certifiedByEmployer: Yup.string().required('Certified by Employer is required'),
  fund: Yup.number().required('Fund is required').positive('Fund must be positive'),
  // nominee
    name: Yup.string().required('Name is required'),
    relation: Yup.string().required('Relation is required'),
    age: Yup.number().required('Age is required').positive('Age must be positive'),
    nominedob: Yup.date().required('Date of Birth is required'),
    isMinor: Yup.boolean(),
    guardianName: Yup.string().when('isMinor', {
        is: true,
        then: Yup.string().required('Guardian Name is required'),
        // otherwise: Yup.string().notRequired(),
      }),
      guardianRelation: Yup.string().when('isMinor', {
        is: true,
        then: Yup.string().required('Guardian Relation is required'),
        // otherwise: Yup.string().notRequired(),
      }),
      remark: Yup.string().when('isMinor', {
        is: true,
        then: Yup.string().required('Remark is required'),
        // otherwise: Yup.string().notRequired(),
      }),

  // Date 
  dob:Yup.date().required('Date of Birth is required'),
  entranceDate:Yup.date().required('Entrance Date is required'),
  resignationDate:Yup.date('Resignation Date is required').min(Yup.ref('appointmentDate'),'Resignation Date must be greater than or equal to Appointment Date'),
  appoinmentDate:Yup.date().required('Appointment Date is required').min(Yup.ref('entranceDate'), 'Appointment Date must be greater than or equal to Entrance Date'),
  retirementDate:Yup.date().required('Retirement Date is required'),

  //unit 
  unit: Yup.string().required('Unit is required'),
  subUnit: Yup.string().required('Sub Unit is required'),
  membershipWitness: Yup.string().required('Membership Witness is required'),
  //addres
  addressLine1: Yup.string().required('Address Line 1 is required'),
  addressLine2: Yup.string(),
  addressLine3: Yup.string(),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  pin: Yup.string().required('Pin is required'),
});



const MemberForm = () => {

   // Initial form values
   const initialValues = {
    // id Numbers
        memberId: '',
        payStaffNo: '',
        hrmsNo: '',
        appFormNo: '',

   // dates
        dob:'',
        entranceDate:'',
        appoinmentDate:'',
        resignationDate:'',
        retirementDate:'',

   // unit
        unit: '',
        subUnit: '',
        membershipWitness: '',

  // nomine
        name: '',
        relation: '',
        age: '',
        nominedob: '',
        isMinor: false,
        guardianName: '',
        guardianRelation: '',
        remark: '',
   //Person     
        title: '',
        firstName: '',
        lastName: '',
        designation: '',
        fatherName: '',
        bankName: '',
        bankAcNo: '',
        certifiedByEmployer: '',
        fund: '',
  // address
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      city: '',
      state: '',
      pin: '',
};

  
  
  return (  
    <LayoutContainer >
      <Card sx={{backgroundColor:'#ccffff'}}>
        <CardContent>
        {/* <Typography variant='h4' gutterBottom >
          Member Details 
        </Typography> */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values)=>{console.log(values)}}
        >
          {({values, setFieldValue, isSubmitting}) =>(
              
             
            <Form>
              <Grid container   sx={{ margin: '8px' }}>
                <Grid item xs={6} sx={{display:'flex', flexDirection:'column',flex:1}} >
                  <IDNumbers  values={values}   />
                  <PersonalDetails values={values}   />
                  <DatesForm values={values}    />
                </Grid>
                <Grid item xs={6}   sx={{display:'flex',flexDirection:'column', flex:1}}>
                  <MemberShip values={values}     />
                  <NomineeDetails   values={values} setFieldValue={setFieldValue}    />
                  <Address    values={values}    />
                </Grid>
              </Grid>
              <Box  >
                          <Button
                              type='submit'
                              variant='contained'
                              color='primary'
                              startIcon={<SaveOutlinedIcon/>}
                             
                              size="medium"
                               margin='normal'
                              sx={{ margin: '8px' }}
                          >
                              Save
                          </Button>
                          <Button
                              type='submit'
                              variant='contained'
                              color='secondary'
                              startIcon={<EditOutlinedIcon/>}
                              margin='normal'
                              sx={{ margin: '8px' }}
                              size="medium"
                          >
                              Edit
                          </Button>
                          <Button
                              type='button'
                              variant='contained'
                              color='warning'
                              startIcon={<DeleteOutlineOutlinedIcon/>}
                              margin='normal'
                              size="medium"
                              sx={{ margin: '8px' }}
                          >
                              Delete
                          </Button>
                          <Button
                              type='button'
                              variant='contained'
                              color='success'
                              startIcon={<CancelOutlinedIcon/>}
                              margin='normal'
                              size="medium"
                              sx={{ margin: '8px' }}
                          >
                              Close
                          </Button>
                  
              </Box>
            </Form>
          )}
        </Formik>
        </CardContent>
      </Card>
    </LayoutContainer>
    
  )
}

export default MemberForm;
