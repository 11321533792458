import React, { useState } from 'react'
import './navbar.scss';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SearchIcon from '@mui/icons-material/Search';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import DehazeIcon from '@mui/icons-material/Dehaze';
import Profile from '../profile/profile';
import Sidebar from '../sidebar/Sidebar';
// import logo from './../../assets/Calsoft_Logo.png';

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  
  const handleSidbarToggle = () =>{
    setSidebar(!sidebar)
  }

  const username = localStorage.getItem('Name')
 

  // const handleProfileToggle = () =>{
  //   console.log(open)
  //   setProfileBar(!open)
  // }  

  return (
    <div>
         <div className='navbar'>        
        <div className="wrapper">  
              <div className="logo">
                  <DehazeIcon 
                    className='icon' 
                    fontSize='large' 
                    onClick={handleSidbarToggle}
                    />   
                  <span className='titl'>TELECOM ENGINEERING COOPERATIVE CREDIT AND THRIFT SOCIETY LIMITED </span>
              </div>
              {/* <div className='title'> 
                                     
              </div> */}
              <div className="items">
                      <div className='groupicon'>
                        <NotificationsNoneIcon fontSize='medium'   className='icon'/>
                        <SearchIcon fontSize='medium'  className='icon' />
                        <HelpOutlineIcon fontSize='medium'  className='icon'/>
                      </div>
                      <div className='user'>
                            <div className='username'>{username}</div>
                            <div  onClick={()=>setOpen(!open)}>
                              <AccountCircleRoundedIcon fontSize="large"  className='usericon'  />
                            </div>
                      </div>
              </div>
              <div className='profile-container'>
                {
                   open && <Profile  open={open} setOpen={setOpen}   />
                }
              </div>
        </div>
    </div>
      
    </div>
  )
}

export default Navbar;
