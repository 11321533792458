import React from 'react';
import {Routes,Route} from 'react-router-dom'
// import Receipts from './Receipts';
// import AccountLedgers from './accountLedgers';
// import DailyReport from './DailyReport';
import AllReport from './allReports';
import './report.scss'

const Reports = () => {
  return (
    <div className='report-continer' >       
        <div className="homeconatiner">
            <div className="widget" >
              <Routes>
                    {/* <Route path="/" element={<Receipts/>}/>  */}
                    <Route path="/" element={<AllReport />}/>
                    {/* <Route path="/" element={<DailyReport/>}/> */}
                    {/* <Route path="/accountledgers" element={<AccountLedgers/>}/> */}
                   {/* <Route path="/dailyreport" element={<DailyReport/>}/> */}
              </Routes>
            </div>
        </div>

    </div>
  )
}

export default Reports
