import {  Card, CardContent, FormControl, Grid, NativeSelect, Typography } from '@mui/material'
import React from 'react'


// const data =[{
//   sno:1,
//   id:1,
//   account:34567,
//   unit:4

// }]



const LoanSuretySection = ({values, touched, errors, handleChange}) => {
  
  return (
    <Card Paper elevation={0}  sx={{ border:'1px solid gray'}}>
        <CardContent>
          <Typography variant="h6" sx={{ display:'flex',justifyContent:'start',color:'#0b0e30',}} >
            Loan Surety
          </Typography>
          <Grid>
          <FormControl fullWidth variant='outline'>
          <NativeSelect
              defaultValue={'cmdWitnessId'}
              inputProps={{
                name: 'age',
                id: 'uncontrolled-native',
              }}
              labelId="paymentModeLabel"
              id="paymentMode"
               margin="normal"
              value={values.paymentMode}
              onChange={handleChange}
              error={touched.paymentMode && Boolean(errors.paymentMode)}
            >   
              <option value="Admission" selected>cmdWitnessId</option>
            </NativeSelect>
        </FormControl>
          </Grid>
        
           
        </CardContent>
    </Card >
  )
}

export default LoanSuretySection;
