import React from 'react';
import { TextField, FormControlLabel, Checkbox, Box, Typography, Grid, Paper } from '@mui/material';
import {  Field, ErrorMessage } from 'formik';


const NomineeDetailForm =({ values, setFieldValue  })=>{
  return(
  <Box  sx={{margin: '8px', padding:'8px'}} component={Paper} elevation={3}>
     <Typography variant="h6" component="h3" sx={{  display:'flex', justifyContent:'start', color:'#0b0e30' }} >
        Nominee  
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={6}>
        <Field 
              as={TextField} 
              id="name" 
              type="text"
              label="name"
              variant="outlined"
              value={values.name}
              fullWidth
              margin="normal"
              required
              helperText={<ErrorMessage name="id"/>} 
          />
          <Field 
              id="relation" 
              as={TextField} 
              label="Relation"
              type="text"
              value={values.relation}
              variant="outlined"
              fullWidth
              margin="normal"
              required
              helperText={<ErrorMessage name="relation"/>} />
          <Field 
              id="age" 
              as={TextField}
              variant="outlined" 
              label="Age" 
              values={values.age}
              type="number" 
              fullWidth
              margin="normal"
              required
              helperText={<ErrorMessage name="id"/>} 
           /> 
           <Field id="nominedob" 
                  as={TextField} 
                  label="Date of Birth" 
                  type="date"
                  fullWidth
                  variant="outlined"
                  values={values.nominedob} 
                  margin="normal"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={<ErrorMessage name="dob"/>} 
            />

        </Grid>
        <Grid item xs={6}>
        <FormControlLabel
              control={
                <Field 
                  id="isMinor"
                  type="checkbox"
                  as={Checkbox}
                  checked={values.isMinor} 
                  onChange={(e) => setFieldValue('isMinor', e.target.checked)} />}
                  label="Minor"
                  fullWidth
            />
            {values.isMinor && (
              <>
                <Field 
                     id="guardianName" 
                    as={TextField} 
                    label="Guardian id" 
                    type="text"
                    variant="outlined" 
                    value ={values.guardianName}
                    margin="normal"
                    required
                    fullWidth
                    helperText={<ErrorMessage name="guardianName"/>}
                   />
                <Field 
                  id="guardianRelation" 
                  as={TextField} 
                  label="Guardian Relation" 
                  type="text"
                  value ={values.guardianRelation}
                  variant="outlined" 
                  values={values.guardianRelation}
                  margin="normal"
                  required
                  fullWidth
                  helperText={<ErrorMessage name="guardianName"/>}
                  />
              </>

          )}
                <Field
                  id="remark" 
                  as={TextField} 
                  label="Remark" 
                  type="text"
                  value ={values.remark}
                  variant="outlined" 
                  margin="normal"
                  required
                  fullWidth
                  helperText={<ErrorMessage name="remarks"/> }                 
                />
          
          </Grid>
          </Grid>
        
        
         </Box>
         )
}



export default NomineeDetailForm;
