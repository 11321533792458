import React, { useState } from 'react';
import {useDispatch } from 'react-redux';
import { Formik, Form, Field} from 'formik';
import { TextField, Button, Stack , Container, Divider, CircularProgress ,Grid, Card, CardHeader, CardContent, InputAdornment, IconButton, Box } from '@mui/material';
import  axios  from 'axios';
import * as Yup from 'yup';
import './forgot-password.scss'
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const LayoutContainer = styled(Box)`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  // padding: 16px;
  // background-color:  #8b0ec4;
  top: 3%;
  left: 40%;
`;

const StyledCard = styled(Card)`
  max-width: 400px;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  padding: 24px;
`;

const Header = styled(CardHeader)`
  text-align: center;
  margin-bottom: 16px;
  color: blueviolet;
`;

const FormStyled = styled(Form)`
  width: 100%;
`;

const SubmitBtn = styled(Button)`
  margin-top: 16px;
  background-color: primary;
  color: #fff;
  &:hover {
    background-color: #303f9f;
  }
`;


const ForgotPassword = () => {

  const initialValues = {
    OldPassword:'',
    NewPassword:'',
    ConfirmPassword:''
  }

  const validationSchema = Yup.object().shape({
    OldPassword: Yup.string().required('Old Password is required'),
    NewPassword: Yup.string().min(6, 'New Password must be at least 6 characters').required('New Password is required'),
    ConfirmPassword: Yup.string().oneOf([Yup.ref('NewPassword'), null], 'Passwords must match').required('Confirm Password is required'),
  });

  const handleSubmit =async (values, {setSubmitting, resetForm })=>{
    const token = localStorage.getItem('token');
    console.log(values)
    console.log(token)
    await axios.post('http://103.166.13.57/society/Admin/Home/ChangePassword',
    { Action:'Password', values }, {
      headers: {
        Authorization: `Bearer ${token}`, 
      },
    })
    .then((response) => {
      console.log(response.data);
      resetForm();
    })
    .catch((error) =>{
    console.log(error)
  })
  .finally(()=>{
    setSubmitting(false)
  })
 }

      const [showPassword, setShowPassword] = useState(false);
      const [shownewPassword, setShowNewPassword]= useState(false);
      const [confirmPassword, setConfirmPassword]= useState(false);

      const handleNewPassowrd =()=>{
        setShowNewPassword(!shownewPassword);
      } 

      
      const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
      };

      const handleConfirmShowPassword = () => {
        setConfirmPassword(!confirmPassword);
      };

      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };


  return (
    <LayoutContainer  >       
          <StyledCard >
              <Header
                title="Forgot Password"
                titleTypographyProps={{ variant: 'h5', component: 'h2' }}
              />
              
              < Divider/>

              <CardContent>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ values,isSubmitting, handleChange, handleSubmit,  errors, touched })=>(
                      <FormStyled  onSubmit={handleSubmit} >
                        <Grid container spacing={1}>
                        <Grid item xs={12}>
                        <Field
                          as={TextField}
                          id="OldPassword"
                          name="OldPassword"
                          label="Old Password"
                          type={showPassword ? 'text' : 'password'}
                          value={values.OldPassword}
                          errors={errors.OldPassword && touched.OldPassword}
                          helperText={errors.title}
                          InputProps={{
                            endAdornment:(
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }} 
                          variant="outlined"
                          margin="normal"
                           required
                          fullWidth
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <Field
                          as={TextField}
                          id="NewPassword"
                          name="NewPassword"
                          type={shownewPassword ? 'text' : 'password'}
                          label="New Password"
                          value={values.NewPassword}
                          InputProps={{
                            endAdornment:(
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleNewPassowrd}
                                  edge="end"
                                >
                                  {shownewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          errors={errors.NewPassword && touched.NewPassword}
                          helperText={errors.title}
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <Field
                          as={TextField}
                          id="ConfirmPassword"
                          name="ConfirmPassword"
                          label="Confirm Password"
                          value={values.ConfirmPassword}
                          type={confirmPassword ? 'text' : 'password'}
                          errors={errors.ConfirmPassword && touched.ConfirmPassword}
                          helperText={errors.title}
                          variant="outlined"
                          InputProps={{
                            endAdornment:(
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleConfirmShowPassword}
                                  edge="end"
                                >
                                  {confirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          margin="normal"
                          required
                          fullWidth
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <SubmitBtn 
                          type="submit"
                          variant='contained'
                          color="primary"
                          // disabled={isSubmitting}
                          onClick={handleSubmit}
                          margin="normal"
                          fullWidth
                        >
                             {isSubmitting ? <CircularProgress size={24} /> : 'Forget'}
                        </SubmitBtn>
                        </Grid>
                        </Grid>
                      </FormStyled>
                    
                    )}
                  </Formik>
              </CardContent>
          </StyledCard>
      </LayoutContainer>

  )
}

export default ForgotPassword;
