import React from 'react'
import {Formik, Form, Field} from 'formik'
import { Box, Container, MenuItem, TextField, Button, Paper} from '@mui/material'
import * as Yup from 'yup';
import styled from '@emotion/styled';

const LayoutContainer = styled(Container)`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: start;
//   max-width: 1600px;
  width: 30%;
  min-height: 30vh;
  height: 40vh;
  padding: 16px;
  top: 12%;
  left:18%;
//   background-color: #ccffff;
`;

const FormBox= styled(Box)`  
    flex-direction: column;
     width: 100%;
    margin: 16px;
    padding: 24px;
    backgroundColor: #ccffff ;
`

const ContainerStyle = styled(Container)`
        display: flex;
        width: 100%;
        flex-direction: column;
        height: 100vh;
`
const validationSchema = Yup.object({
    totalRecovery:Yup.number().required('Total Recovery is required'),
    recoveryListName:Yup.string().required('Recovery List Name is required'),

})


const initialValues ={
    totalRecovery:'',
    recoveryListName:'',
}

const recoveryListOptions = [
    { value: '112023-LKOPGMTD---All', label: '112023-LKOPGMTD---All' },
    { value: '112024-LKOPGMTD---All', label: '112024-LKOPGMTD---All' },
    { value: '112025-LKOPGMTD---All', label: '112025-LKOPGMTD---All' },
    // Add more options as needed
  ];


const AddRecoveryList = () => {

   

    const handleSubmit = (values) => {
        // Handle form submission
        console.log(values);
      };
    

  return (
    <LayoutContainer component={Paper}  elevation={3} >
       
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({errors, touched, handleChange, handleBlur, values})=>(
                <Form>
                    <ContainerStyle >
                        
                        <FormBox >
                        <Field
                            as={TextField}
                            name="totalRecovery"
                            label="Total Recovery"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.totalRecovery}
                            error={touched.totalRecovery && Boolean(errors.totalRecovery)}
                            helperText={touched.totalRecovery && errors.totalRecovery}
                        />
                         <Field
                                as={TextField}
                                select
                                name="recoveryListName"
                                label="Recovery List Name"
                                variant="outlined"
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.recoveryListName}
                                error={touched.recoveryListName && Boolean(errors.recoveryListName)}
                                helperText={touched.recoveryListName && errors.recoveryListName}
                         >
                        {recoveryListOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                        </Field>
                        <Button type="submit" variant="contained" color="primary"  sx={{margin:'16px'}} >
                             Transfer to OD
                         </Button>
                        </FormBox>
                     
                    </ContainerStyle>
                </Form>
            )}
        </Formik>
       
    </LayoutContainer>
  )
}

export default AddRecoveryList
