import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField, Button, Typography, Container, Box, IconButton, CircularProgress, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, Snackbar,
} from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';

const LayoutContainer = styled(Container)`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  padding: 16px;
  top: 12%;
  left:18%;
`;

const ADDBtn = styled(Button)`
  display:flex; 
  justify-content: center; 
  margin-bottom: 16px; 
  width: 15%;
  align-items:center; 
  padding:8px;
`;

const TableRowHeadStyle  = styled(TableRow)`
  display:flex;
  flex-direction:row;
  background-color:#0E49B5;
  color:white; 
  min-width:200px; 
  justify-content:start;
`;

const TableCellHeadStyle = styled(TableCell)`
  display:flex;
  flex-direction:column;
  color:white; 
  min-width:200px; 
  justify-content:start;
`;

const TableRowStyle  = styled(TableRow)`
  display:flex;
  flex-direction:row;
  min-width:200px; 
  justify-content:start;
`;

const TableCellStyle = styled(TableCell)`
  display:flex;
  flex-direction:row;
  min-width:200px; 
  justify-content:start;
  alignItems:'center'
`;




const API_URL = 'https://tecslkoupe.com';
const token = localStorage.getItem('token');

const UserDataProfile = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize] = useState(10);

  const fetchUsers = async (pageIndex) => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/Admin/Home/Users`, {
        Action: 'GetAll',
        pageIndex,
        pageSize,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data.List);
      setTotalRecords(response.data.Footer[0].TotalRecord);
      setLoading(false);
    } catch (error) {
      setError(`Failed to fetch users: ${error}`);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers(currentPage);
  }, [currentPage]);

  const handleOpen = (user = null) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUser(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleAddUpdateUser = async (values, { setSubmitting, resetForm }) => {
    try {
      if (selectedUser) {
        await axios.post(`${API_URL}/Admin/Home/Users`, 
          { ...values, Action: 'Update', UserID: selectedUser.UserID }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        setSnackbarMessage('User updated successfully');
      } else {
        await axios.post(`${API_URL}/Admin/Home/Users`, 
          { ...values, Action: 'Insert' }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        setSnackbarMessage('User added successfully');
      }
      fetchUsers(currentPage);
      setSnackbarOpen(true);
      setSubmitting(false);
      handleClose();
      resetForm();
    } catch (error) {
      setError(`Failed to add/update user: ${error}`);
      setSnackbarMessage(error.message);
      setSubmitting(false);
    }
  };

  const handleDeleteDialogOpen = (userID) => {
    setUserToDelete(userID);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setUserToDelete(null);
  };

  const handleDeleteUser = async () => {
    try {
      await axios.post(`${API_URL}/Admin/Home/Users`, 
        { UserID: userToDelete, Action: 'Delete' }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      setSnackbarMessage(`User with ID ${userToDelete} deleted successfully`);
      fetchUsers(currentPage);
      setSnackbarOpen(true);
      handleDeleteDialogClose();
    } catch (error) {
      setError(`Failed to delete user: ${error}`);
      handleDeleteDialogClose();
    }
  };

  const validationSchema = Yup.object({
    UserName: Yup.string().required('User Name is required'),
    Password: Yup.string().min(6,"Password must be at least 6 characters").max(16).required('Password Must be required'),
    Name: Yup.string().required('Name is required'),
    Phone: Yup.string().nullable(),
    Address: Yup.string().nullable(),
    Category: Yup.string().required('Category is required'),
    SSACode: Yup.string().nullable(),
    CSCCode: Yup.string().nullable(),
  });

  const renderForm = (isSubmitting, values, handleChange) => (
    <Form>
      <Field 
        as={TextField} 
        name="UserName"
        type="text" 
        label="User Name"
        value={values.UserName} 
        fullWidth 
        margin="normal" 
      />
      <ErrorMessage name="UserName" component="div" className="error" />
      <Field 
        as={TextField} 
        name="Password"
        type="password" 
        label="Password"
        required
        value={values.Password} 
        fullWidth 
        margin="normal" 
      />
      <ErrorMessage name="UserName" component="div" className="error" />
      <Field 
        as={TextField} 
        name="Name" 
        label="Name" 
        value={values.Name} 
        fullWidth
        margin="normal" 
      />
      <ErrorMessage name="Name" component="div" className="error" />
      <Field 
        as={TextField} 
        name="Phone" 
        label="Phone" 
        value={values.Phone}
        fullWidth 
        margin="normal" 
      />
      <ErrorMessage name="Phone" component="div" className="error" />
      <Field 
        as={TextField} 
        name="Address" 
        label="Address"
        value={values.Address} 
        fullWidth 
        margin="normal" 
      />
      <ErrorMessage name="Address" component="div" className="error" />
      <Field 
        as={TextField} 
        name="Category" 
        label="Category"
        value={values.Category} 
        fullWidth 
        margin="normal" 
      />
      <ErrorMessage name="Category" component="div" className="error" />
      <Field 
        as={TextField} 
        name="SSACode" 
        label="SSA Code"
        value={values.SSACode} 
        fullWidth 
        margin="normal" 
      />
      <ErrorMessage name="SSACode" component="div" className="error" />
      <Field 
        as={TextField} 
        name="CSCCode" 
        label="CSC Code"
        value={values.CSCCode} 
        fullWidth 
        margin="normal" 
      />
      <ErrorMessage name="CSCCode" component="div" className="error" />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={isSubmitting}
        style={{ marginTop: '16px' }}
      >
        {isSubmitting ? 'Submitting...' : 'Submit'}
      </Button>
    </Form>
  );

  return (
    <LayoutContainer 
      //    
      >
      <Typography variant="h4" sx={{ display: 'flex', justifyContent: 'flex-start' }} gutterBottom>
        User Profiles
      </Typography>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <ADDBtn
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => handleOpen()}
          >
            Add User
          </ADDBtn>
        
          <TableContainer component={Paper}  elevation={3}>
            <Table>
              <TableHead>
                <TableRowHeadStyle  >
                  <TableCellHeadStyle>User ID</TableCellHeadStyle>
                  <TableCellHeadStyle>User Name</TableCellHeadStyle>
                  <TableCellHeadStyle>Name</TableCellHeadStyle>
                  <TableCellHeadStyle>Phone</TableCellHeadStyle>
                  <TableCellHeadStyle>Address</TableCellHeadStyle>
                  <TableCellHeadStyle>Category</TableCellHeadStyle>
                  <TableCellHeadStyle>SSA Code</TableCellHeadStyle>
                  <TableCellHeadStyle>CSC Code</TableCellHeadStyle>
                  <TableCellHeadStyle>Actions</TableCellHeadStyle>
                </TableRowHeadStyle>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRowStyle key={user.UserID}>
                    <TableCellStyle>{user.UserID}</TableCellStyle>
                    <TableCellStyle>{user.UserName}</TableCellStyle>
                    <TableCellStyle>{user.Name}</TableCellStyle>
                    <TableCellStyle>{user.Phone || 'N/A'}</TableCellStyle>
                    <TableCellStyle>{user.Address || 'N/A'}</TableCellStyle>
                    <TableCellStyle>{user.Category}</TableCellStyle>
                    <TableCellStyle>{user.SSACode || 'N/A'}</TableCellStyle>
                    <TableCellStyle>{user.CSCCode || 'N/A'}</TableCellStyle>
                    <TableCellStyle>
                      <IconButton onClick={() => handleOpen(user)} color="primary">
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteDialogOpen(user.UserID)} color="warning">
                        <DeleteIcon />
                      </IconButton>
                    </TableCellStyle>
                  </TableRowStyle>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" justifyContent="center" alignItems="center" margin="16px 0">
            <Button
              variant="contained"
              onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <Typography variant="h6" style={{ margin: '0 16px' }}>
              Page {currentPage} of {Math.ceil(totalRecords / pageSize)}
            </Typography>
            <Button
              variant="contained"
              onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
              disabled={currentPage === Math.ceil(totalRecords / pageSize)}
            >
              Next
            </Button>
          </Box>
        </>
      )}

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{selectedUser ? 'Update User' : 'Add User'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedUser ? 'Update the user information below' : 'Fill in the information below to add a new user'}
          </DialogContentText>
          <Formik
            initialValues={selectedUser || {
              UserName: '',
              Name: '',
              Phone: '',
              Address: '',
              Category: '',
              SSACode: '',
              CSCCode: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleAddUpdateUser}
          >
            {({ isSubmitting, values, handleChange }) => renderForm(isSubmitting, values, handleChange)}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the user with ID {userToDelete}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteUser} ccolor="warning">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </LayoutContainer>
  );
};

export default UserDataProfile;
