import React, {useState} from 'react';
import {Grid, Container, Paper} from '@mui/material';
import AccountForm from './AccountForm';
import ReusableTable from '../../reuseable/ReusableTable';
import styled from '@emotion/styled';


const LayoutContainer = styled(Container)`  
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content:start;
  max-width: 600px;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  adding: 16px;
  top: 12%;
  left:18%;
  marginTop: theme.spacing(2);
  marginBottom: theme.spacing(2);
  backgroundColor:'#ccffff';
`;

const categories = ['Asset', 'Expenditure', 'Income', 'Liability'];

const initialData = [
  { id: 1, name: 'A.G.M.', category: 'Expenditure', opening: 0 },
  { id: 2, name: 'Admission Fees', category: 'Income', opening: 0 },
  { id: 3, name: 'Advance Against EPF A/c', category: 'Asset', opening: 28000 },
  // Add more initial data here
];


const  columns = [
    { field: 'SNO', headerName: 'SNO', width: 130 },
    { field: 'ID', headerName: 'ID', width: 180 },
    { field: 'Account Name', headerName: 'Account Name', width: 130 },
    { field: 'Category', headerName: 'Category', width: 150 },
    { field: 'Opening', headerName: 'Opening', width: 150 },
    // { field: 'Action', headerName: 'Action', width: 150 },
]


const CreateAccount  = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editingMember, setEditingMember] = useState(null);

  const handleEditClick = (member) => {
    setEditingMember(member);
    setOpenModal(true);
  };
  return (
    <LayoutContainer component={Paper} elevation={5} spacing={2} >
      <Grid>
          <AccountForm editingMember={editingMember} />
      </Grid>
      <Grid>
          <ReusableTable columns={columns}  data={initialData} onEdit={handleEditClick} />
      </Grid>
      
    </LayoutContainer>
  )
}

export default CreateAccount
