// Dates.js
import React from 'react';
import { Box, Grid, TextField, Typography, Paper } from '@mui/material';
import { Field, ErrorMessage } from 'formik';

const Dates = ({ handleChange, values }) => (

  <Box sx={{margin: '8px', padding:'8px'}} component={Paper} elevation={3} >
     <Typography variant="h6" component="h3" sx={{  display:'flex', justifyContent:'start', color:'#0b0e30', }} >
        Dates
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={6}>
            <Field
                name="dob"
                as={TextField}
                label="Date of Birth"
                type="date"
                value={values.dob}
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={<ErrorMessage name="dateOfBirth" />}
                margin="normal"
        />
          <Field
            name="appointmentDate"
            as={TextField}
            label="Appointment Date"
            type="date"
            value={values.appointmentDate}
            required
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            helperText={<ErrorMessage name="appointmentDate" />}
            margin="normal"
          />

        </Grid>
        <Grid item xs={6}>
        <Field
            name="resignationDate"
            as={TextField}
            label="Resignation Date"
            type="date"
            value={values.resignationDate}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            helperText={<ErrorMessage name="resignationDate" />}
            margin="normal"
        />
        <Field
          name="retirementDate"
          as={TextField}
          label="Retirement Date"
          type="date"
          value={values.retirementDate}
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          helperText={<ErrorMessage name="retirementDate" />}
          margin="normal"
        />   
        </Grid>
      </Grid>
    
   
  </Box>
);

export default Dates;
