import React from 'react';
import { Box, Grid, TextField, Typography, Paper } from '@mui/material';
import { Field, ErrorMessage } from 'formik';


const MemberShip = ({ values, handleChange}) => {
  return (
      <Box  sx={{margin: '8px', padding:'8px'}} component={Paper} elevation={3} >
       <Typography variant="h6" component="h3" sx={{  display:'flex', justifyContent:'start', color:'#0b0e30' }} >
         MemberShip Witness
      </Typography>
      <Grid container spacing={2}>
          <Grid item xs={6}>
              <Field
                as={TextField}
                id="membershipWitness"
                type="text"
                value={values.membershipWitness}
                label="MemberShip Witness"
                variant="outlined"
                onChange={handleChange}
               fullWidth
                helperText={<ErrorMessage name="membershipWitness"/>}
                required   
                margin="normal"           
            />
          </Grid>
      </Grid>
    </Box>
  )
}

export default MemberShip
