import React from 'react';
import { TextField, MenuItem } from '@mui/material';
import { Field } from 'formik';

const CustomDropDown = ({ label, name, options, ...props }) => (
  <Field name={name}>
    {({ field, form }) => (
      <TextField
        {...field}
        select
        fullWidth
        label={label}
        variant="outlined"
        margin="normal"
        error={form.touched[name] && Boolean(form.errors[name])}
        helperText={form.touched[name] && form.errors[name]}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    )}
  </Field>
);

export default CustomDropDown;

