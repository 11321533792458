import React from 'react';
import { Box, Paper,Grid, TextField, Typography } from '@mui/material';
import { Field, ErrorMessage } from 'formik';



const IDNumbers = ({ handleChange, values}) => {
  
  return (
    <Box sx={{margin: '8px', padding:'8px'}} component={Paper} elevation={3}>
        
        <Typography  variant="h6" component="h3"sx={{  display:'flex', justifyContent:'start', color:'#0b0e30', }}  >
           ID Numbers
         </Typography>
          
    <Grid container spacing={2}>
        <Grid item xs={6}>
        
        <Field
          as={TextField}
          id="memberid"
          type="text"
          value={values.memberid}
          label="Member Id"
          variant="outlined"
          onChange={handleChange}
          fullWidth
          helperText={<ErrorMessage name="memberId"/>}
          required
          margin="normal"           
        />
        <Field
          as={TextField}
          id="hrmsNo"
          type="text"
          value={values.hrmsNo}
          label="HRMS NO"
          variant="outlined"
          onChange={handleChange}
          fullWidth
          helperText={<ErrorMessage name="hrmsno"/>}
          required
          margin="normal"          
          />

        </Grid>
        <Grid item xs={6}>
          <Field
            as={TextField}
            id="paystaffno"
            type="text"
            value={values.payStaffNo}
            label="Pay Staff No"
            variant="outlined"
            onChange={handleChange}
            fullWidth
            helperText={<ErrorMessage name="paystaffno"/>}
            required
            margin="normal"         
        />
        <Field
            as={TextField}
            id="appformno"
            type="text"
            value={values.appformno}
            label="App From No"
            variant="outlined"
            onChange={handleChange}
            fullWidth
            helperText={<ErrorMessage name="appformno"/>}
            required
            margin="normal"        
       /> 
        </Grid>
       

    </Grid>
    
    </Box>
  )
}

export default IDNumbers;
