import React from 'react';
import { Container,Grid ,Typography, Paper } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import SelectFinancialYear from './Selectyears';
import ThriftDividendInput from './ThriftDividendInput';
import ShareDetails from './ShareDetails';
import MemberDetails from './MemberDetails';
import styled from '@emotion/styled';


const LayoutContainer = styled(Container)`  
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 105vh;
  height: 100vh;
  padding:16px;
  top: 12%;
  left:18%;
 
`;

const validationSchema = Yup.object().shape({
      ThriftPercetage:Yup.string().required('thrift is required'),
      DividenPersentage:Yup.string().required('Dividen is required'),
      ShareOpening:Yup.number().required('Share opening is required'),
      ShareCollection:Yup.number(),
      SharePaidBefore:Yup.number(),
      SharePaidAfter:Yup.number(),
      Dividen:Yup.number(),
      TFOpening:Yup.number(),
      TFCollection:Yup.number(),
      TFRefunded:Yup.number(),
      TFInterest:Yup.number()
});

const ThriftInterestDividendForm = () => {
  return (
    <Formik
      initialValues={{
        FinancialYear: '',
        ThriftPercentage: '',
        DividendPercentage: '',
        ShareOpening: '',
        ShareCollection: '',
        SharePaidBefore: '',
        SharePaidAfter: '',
        TFOpening: '',
        TFCollection: '',
        TFRefunded: '',
        TFInterest: '',
      
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Handle form submission
      }}
    >
      {(values, handleChange) => (
        <Form>
          <LayoutContainer container spacing={2} component={Paper} elevation={5}>
                     
            <Grid item xs={12}>
              <SelectFinancialYear values={values} handleChange={handleChange} btnLabel={"Get Data"} />
            </Grid>

            <Grid item xs={12}>
              <ThriftDividendInput values={values} handleChange={handleChange} />
            </Grid>

            <Grid item xs={12}>
              <ShareDetails values={values} handleChange={handleChange} />
            </Grid>

            <Grid item xs={12}>
              <MemberDetails values={values} handleChange={handleChange} />
            </Grid>
            
          </LayoutContainer>
        </Form>
      )}
    </Formik>
  );
};

export default ThriftInterestDividendForm;
