import React from 'react';
import {Routes,Route} from 'react-router-dom'
import FinalizeDividend from './divident/finalizeDividend';
import CreateAccount from './CreateAccount';
import ThriftInterestDividendForm from './ThriftInterestCalculation';


const Account = () => {
  return (
    <div className='home'>
    {/* <Navbar/> */}
    <div className="homeconatiner">
        {/* <Sidebar/> */}
        <div className="widget" >
          <Routes>
               <Route path="/*" element={<FinalizeDividend/>}/>
               <Route path="accountheader" element={<CreateAccount/>}/>
               <Route path="divide" element={<ThriftInterestDividendForm/>}/>
          </Routes>
        </div>
    </div>

</div>
  )
}

export default Account;
