import React from 'react';
import Navbar from '../../Layout/navbar/Navbar';
import  NextedSidebar from './../../Layout/sidebar/nextedSideBar';
import {Routes,Route} from 'react-router-dom'
import JournalVoucher from './JournalVoucher';

const Journal = () => {
  return (
    <div className='home'>       
        <div className="homeconatiner">
            {/* <NextedSidebar/> */}
            <div className="widget" >
              <Routes>
                   <Route path="/*" element={< JournalVoucher />}/>
                   {/* <Route path="/LoanLegender" element={<Recoveries/>}/> */}
              </Routes>
            </div>
        </div>

    </div>
  )
}

export default Journal;
