import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  AccountBalance as AccountBalanceIcon, 
  LibraryBooks as LibraryBooksIcon, 
  People as PeopleIcon, 
  Payment as PaymentIcon, 
  Repartition as RepartitionIcon, 
  LockReset as LockResetIcon, 
  SignalCellularAlt as SignalCellularAltIcon, 
  SettingsOutlined as SettingsOutlinedIcon, 
  ExpandLess, 
  ExpandMore 
} from '@mui/icons-material';
import { Box, Collapse, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, useTheme, Typography } from '@mui/material';
import styled from '@emotion/styled';

const sidebarItems = [
  { name: 'Dashboard', icon: <SignalCellularAltIcon className='icon' />, route: '/home/dashboard', children: ['Interest Adjustments', 'Loan Duplicate Voucher', 'Receipts'] },
  { name: 'Member', icon: <PeopleIcon />, route: '/home/members', children: ['MemberMaster', 'LoanLedger', 'LedgerPrinting', 'OtherDeposity', 'ShareMoney', 'ThriftyFund', 'Interest', 'Surety', 'DIF', 'DividendAndTFInterrest'] },
  { name: 'Account', icon: <AccountBalanceIcon />, route: '/home/account', children: ['Account_ header', 'OD', 'Divide', 'Finalize_Dividen', 'Lending Page', 'Rise_Interest_OS'] },
  { name: 'Setup', icon: <SettingsOutlinedIcon />, route: '/home/setup', children: ['Account Ledgers', 'Daily Expenditures', 'Daily Expenditures RTGS', 'Daily Journal', 'Daily Receipts', 'Divident Reports', 'Landing Page', 'Member Slip', 'Other Deposit', 'Cash Book', 'Total Interest OutStanding'] },
  { name: 'Recovery', icon: <RepartitionIcon />, route: '/home/recovery', children: ['Add to Recovery ', 'Transfer From Recovery', 'Upload Reacovery', 'View Recovery'] },
  { name: 'Journal', icon: <LibraryBooksIcon />, route: '/home/journal', children: ['view/Edit', 'Cash_Withdrawl', 'Clear_Suspense', 'Deposit_Cheques', 'Deposited Cheque Reversal', 'Landing page', 'Member Transfer Entries', 'Other Journal Vouchers', 'RR Voucher'] },
  { name: 'Payments', icon: <PaymentIcon />, route: '/home/payments', children: ['GroupDividend', 'LandingPage', 'Loan_And_Advances', 'New_Loan', 'Refund', 'other'] },
  { name: 'Report', icon: <RepartitionIcon />, route: '/home/report', children: ['Add Banks', ' Add units', 'Setting', 'Upload to Website'] },
];

const drawerWidth = 240;

const StyledDrawer = styled(Drawer)`
  width: ${drawerWidth}px;
  flex-shrink: 0;
  opacity: 1;

  & .MuiDrawer-paper {
    width: ${drawerWidth}px;
    box-sizing: border-box;
    background-color: #22577E; /* Sidebar background color */
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const StyledListItem = styled(ListItem)`
  &.Mui-selected {
    background-color: rgba(0, 0, 0, 0.08);
    color: white;
    
    .MuiListItemIcon-root, .MuiListItemText-primary {
      color: white;
    }
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .MuiListItemIcon-root {
    color: #FFFFFF; /* Icon color before selection */
  }

  .MuiListItemText-primary {
    color: #FFFFFF; /* Text color before selection */
  }
`;

const StyledNestedListItem = styled(ListItem)`
  padding-left: 32px;

  &.Mui-selected {
    background-color: rgba(0, 0, 0, 0.08);
    color: white;
    
    .MuiListItemIcon-root, .MuiListItemText-primary {
      color: white;
    }
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .MuiListItemText-primary {
    color: #FFFFFF; /* Nested text color before selection */
  }
`;



const NextedSidebar = () => {
  const [open, setOpen] = useState({});
  const location = useLocation();
 

  const handleClick = (item) => {
    setOpen((prevOpen) => ({ ...prevOpen, [item]: !prevOpen[item] }));
  };

  return (
    <StyledDrawer variant='permanent' anchor='left'  p2 >
        <Typography variant="h4" color="White" sx={{display:'flex', justifyContent:'center', alignItems:'center', padding:'16px'}}>
            Sidebar
          </Typography>
      <Divider/>
      <List >
        {sidebarItems.map((item) => (
          <Box key={item.name}>
            <StyledLink to={item.route} >
              <StyledListItem
                Button
                Selected={location.pathname.includes(item.route)}
                onClick={() => handleClick(item.name)}
              >
                <ListItemIcon  >{item.icon}</ListItemIcon>
                <ListItemText  >{item.name}</ListItemText>
                {item.children.length > 0 && (open[item.name] ? <ExpandLess /> : <ExpandMore />)}
              </StyledListItem>
            </StyledLink>
            {item.children.length > 0 && (
              <Collapse in={open[item.name]} timeout="auto" unmountOnExit  >
                <List component="div" disablePadding>
                  {item.children.map((child) => (
                    <StyledLink to={`${item.route}/${child.toLowerCase().replace(/ /g, '')}`} key={child} >
                      <StyledNestedListItem
                        Button
                        Selected={location.pathname.includes(`${item.route}/${child.toLowerCase().replace(/ /g, '')}`)}
                      >
                        <ListItemText primary={child} />
                      </StyledNestedListItem>
                    </StyledLink>
                  ))}
                </List>
              </Collapse>
            )}
            <Divider />
          </Box>
        ))}
      </List>
    </StyledDrawer>
  );
};

export default NextedSidebar;
