import React, { useState } from 'react';
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup';
import { Grid, Typography, TextField, Button, Box, Container, CardContent, Paper } from '@mui/material';
import Table from './../../reuseable/ReusableTable';
import styled from '@emotion/styled';

//icon
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const validationSchema = Yup.object({
    category:Yup.string().required('Category Must be required'),
    shortName:Yup.string().required('Short Name must be required'),
    bankName:Yup.string().required('Bank name must be required'),
    opening:Yup.number().required('Opening must be greater then 0').positive('Must be positive'),
    micrCode:Yup.string().required('micrCode must be required'),
    accountNo:Yup.string().required('Account number must be required'),
    address:Yup.string().required('Must be required')
});

const initialValues ={
    category:'',
    shortName:'',
    bankName:'',
    opening:'',
    micrCode:'',
    accountNo:'',
    address:''
}

const bankData = [{
    category: 'pvt',
    shortName:'sbi',
    bankName:'state bank of india',
    opening:'1000',
    micrCode:'abcd5656',
    accountNo:'A345436',
    address:'indore',
}, {
    category: 'pvt',
    shortName:'sbi',
    bankName:'state bank of india',
    opening:'1000',
    micrCode:'abcd5656',
    accountNo:'A345436',
    address:'indore',
},
{
    category: 'pvt',
    shortName:'sbi',
    bankName:'state bank of india',
    opening:'1000',
    micrCode:'abcd5656',
    accountNo:'A345436',
    address:'indore',
}]

const LayoutContainer = styled(Container)`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: start;
//   max-width: 1600px;
  width: 100%;
//   min-height: 100vh;
//   height: 100vh;
  padding: 16px;
  top: 12%;
  left:18%;
//   background-color: #ccffff;
`;

const ADDBtn = styled(Button)`
  display:flex; 
  justify-content: center; 
  margin-bottom: 16px; 
  width: 15%;
  align-items:center; 
  padding:8px;
`




const CreateBank = () => {
   
    const [data, setData] = useState([])
    const [loading, setLoading]= useState(false);

   

    const columns = [
        {id:'category', label:'Category'},
        {id:'shortName', label:'Short Name'},
        {id:'bankName', label:'Bank Name'},
        {id:'opening', label:'Opening'},
        {id:'micrCode', label:'MICR CODE'},
        {id:'accountNo', label:'Account No'},
        {id:'address', label: 'Address'},
        
    ]

    const handleSubmit =(value, {resetForm}) =>{
        console.log(value);
        resetForm()
    }

  return (
    <LayoutContainer component={Paper} >
    <CardContent>
        <Typography variant='h5' sx={{marginBottom:'16px',  display:'flex', justifyContent:'start', color:'#0b0e30',}} >
            Create Bank
        </Typography>
    
    <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={()=>handleSubmit()}
        sx={{    position: 'absolute',top: '10%',
            left: '15%',
            padding: '5px',
            margin:'10px',
            width:'80%',
            marginTop: '16px',
            marginBottom: '16px',
            backgroundColor:'#ccffff'}}
    >
        {({errors, touched, values})=>(
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                        <Field
                            name="category"
                            as={TextField}
                            label="Category"
                            variant="outlined"
                            value={values.category}
                            type="text"
                            fullWidth
                            required
                           
                            error={touched.category && Boolean(errors.category)}
                            helperText={touched.category && errors.category}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Field
                            name="shortName"
                            as={TextField}
                            label="Short Name"
                            variant="outlined"
                            value={values.shortName}
                            type="text"
                            fullWidth
                            required
                          
                            error={touched.shortName && Boolean(errors.shortName)}
                            helperText={touched.shortName && errors.shortName}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Field
                            name="bankName"
                            as={ TextField}
                            label="Bank Name"
                            variant="outlined"
                            value={values.bankName}
                            type="text"
                            fullWidth
                            required
                          
                            error={touched.bankName && Boolean(errors.bankName)}
                            helperText={touched.bankName && errors.bankName}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        
                        <Field
                            name="opening"
                            as={ TextField}
                            label="Opening"
                            variant="outlined"
                            value={values.opening}
                            type="text"
                            fullWidth
                            required
                          
                            error={touched.opening && Boolean(errors.opening)}
                            helperText={touched.opening && errors.opening}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Field
                            name="micrCode"
                            as={ TextField}
                            label="MICR CODE"
                            variant="outlined"
                            value={values.micrCode}
                            type="text"
                            fullWidth
                            required
                           
                            error={touched.micrCode && Boolean(errors.micrCode)}
                            helperText={touched.micrCode && errors.micrCode}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Field
                            name="accountNo"
                            as={ TextField}
                            label="Accoount NO"
                            variant="outlined"
                            value={values.accountNo}
                            type="text"
                            fullWidth
                            required
                           
                            error={touched.accountNo && Boolean(errors.accountNo)}
                            helperText={touched.accountNo && errors.accountNo}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Field
                            name="address"
                            as={ TextField}
                            label="Address"
                            variant="outlined"
                            value={values.address}
                            type="text"
                            fullWidth
                            required
                          
                            error={touched.address && Boolean(errors.address)}
                            helperText={touched.address && errors.address}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{margin:'16px'}}>
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            startIcon={<SaveOutlinedIcon/>}
                            sx={{ color:'white', fontSize:'16px', fontWeight:600, marginRight:'8px'}}
                            size="medium"
                           
                        >
                            Save
                        </Button>
                        <Button
                            type='submit'
                            variant='contained'
                            color='secondary'
                            startIcon={<EditOutlinedIcon/>}
                            sx={{ color:'white', fontSize:'16px', fontWeight:600, marginRight:'8px'}}
                            size="medium"
                        >
                            Edit
                        </Button>
                        <Button
                            type='button'
                            variant='contained'
                            color='warning'
                            startIcon={<DeleteOutlineOutlinedIcon/>}
                            sx={{ color:'white', fontSize:'16px', fontWeight:600, marginRight:'8px'}}
                            size="medium"
                           
                        >
                            Delete
                        </Button>
                        <Button
                            type='button'
                            variant='contained'
                            color='success'
                            startIcon={<CancelOutlinedIcon/>}
                            sx={{ color:'white', fontSize:'16px', fontWeight:600, marginRight:'8px'}}
                            size="medium"
                           
                        >
                            Close
                        </Button>
                    </Grid>
                </Grid>
                <Box component={Paper}  >
                    {
                        loading ? (
                            <p>Loading...</p>
                        ):(<Table data={ bankData} columns={columns} />)
                    }
                 </Box>

            </Form>
        )}
    </Formik>
    </CardContent>
    </LayoutContainer>
  )
}

export default CreateBank
