import { Menu, MenuItem } from '@mui/material'
import React from 'react'


import {  Button, Divider,  } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import LogoutIcon from '@mui/icons-material/Logout';
import PortraitIcon from '@mui/icons-material/Portrait';
import PersonIcon from '@mui/icons-material/Person';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SettingsIcon from '@mui/icons-material/Settings';
import LockResetIcon from '@mui/icons-material/LockReset';
import styled from '@emotion/styled';
import { alpha } from '@mui/material/styles';


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top-10px',
      horizontal: 'right-2px',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: '60px',
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '16px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));





const Profile = ({open, setOpen }) => {
  
  const navigate = useNavigate();  

  const handleLogout = () => {
    // Remove JWT token from local storage
    localStorage.removeItem('token');

    // Navigate to login page
    navigate('/');
  };

const handleProfile =()=>{
  navigate('/home/profile')
}

const handleUser = ()=>{
  navigate('/home/user')
}


const handleOfficeInfo = ()=>{
  navigate('/home/office')
}


const handleSetting = ()=>{
  navigate('/home/setting')
}

const handleBank = ()=>{
  navigate('/home/bank')
}

const handleChangePassword=()=>{
  navigate('/home/changepassword')
}

  return (

    <StyledMenu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        // anchorEl={anchorEl}
        open={open}
        onClose={(e)=>setOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
       
      >
      
        {/* <MenuItem onClick={handleProfile} >
        <PortraitIcon variant="h4" className={Styles.icon}/>
        Profile
        </MenuItem> */}
        <MenuItem variant="h4" onClick={handleUser}>
          <PersonIcon  sx={{ color:'blue', fontSize:'20px', fontWeight:700, marginRight:'8px'}} />
          User
        </MenuItem>
        <MenuItem  variant="h4" onClick={handleOfficeInfo}>
          <LocalPostOfficeIcon  sx={{ color:'blue', fontSize:'20px', fontWeight:700, marginRight:'8px'}} />
          Office Information
        </MenuItem>
        <MenuItem variant="h4" onClick={handleBank} >
          <AccountBalanceIcon   sx={{ color:'blue', fontSize:'20px', fontWeight:700, marginRight:'8px'}}/>
          Bank
        </MenuItem>
        <MenuItem variant="h4" onClick={handleSetting} >
        <SettingsIcon sx={{ color:'blue', fontSize:'20px', fontWeight:700, marginRight:'8px'}}/>
          Setting
        </MenuItem>
        <MenuItem >
          <Button onClick={handleChangePassword} variant='outlined' color='secondary' >
            <LockResetIcon />
            ChnagePassword
          </Button>
        </MenuItem>
        <Divider />
        <MenuItem  >
          <Button variant="contained" color="warning" onClick={handleLogout} fullWidth>
            <LogoutIcon sx={{ color:'white', fontSize:'20px', fontWeight:700, marginRight:'8px'}}/>
              Logout
          </Button>
        </MenuItem>
       
      </StyledMenu>
  )
}

export default Profile
