import { Card, CardContent, TextField, Typography } from '@mui/material'
import { Field } from 'formik'
import React from 'react'

const NormalLoanSection = ({values, touched, errors}) => {
   
  return (
    <Card sx={{ border:'1px solid gray'}} Paper elevation={0}>
      <CardContent>
        <Typography variant='h6'> Normal Loan</Typography>
        <Field
            name="normalLoanBalance"
            as={TextField}
            label="Balance"
            value={values.normalLoadBalance}
            fullWidth
            margin="normal"
            required           
            error={touched.normalLoadBalance && Boolean(errors.normalLoadBalance)}
            helperText={touched.normalLoadBalance && errors.normalLoadBalance}
        />
        <Field
            name="normalLoanMonthlyRecovery"
            as={TextField}
            label="Monthly Recovery"
            value={values.normalLoanMonthlyRecovery}
            fullWidth
            
            margin="normal"
            required         
            error={touched.normalLoanMonthlyRecovery && Boolean(errors.normalLoanMonthlyRecovery)}
            helperText={touched.normalLoanMonthlyRecovery && errors.normalLoanMonthlyRecovery}
        />
         <Field
            name="normalLoanDate"
            as={TextField}          
            value={values.normalLoanDate}
            type="date"
            fullWidth
             margin="normal"
            required
            error={touched.normalLoanDate && Boolean(errors.normalLoanDate)}
            helperText={touched.normalLoanDate && errors.normalLoanDate}
        />
        <Field
            name="normalAmountSanctioned"
            as={TextField}
            label="Amount Sanctioned"
            value={values.normalAmountSanctioned}
            fullWidth
             margin="normal"
            required       
            error={touched.normalAmountSanctioned && Boolean(errors.normalAmountSanctioned)}
            helperText={touched.normalAmountSanctioned && errors.normalAmountSanctioned}
        />
      </CardContent>
    </Card>
  )
}

export default NormalLoanSection;
