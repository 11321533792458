import React from 'react'
import { Card, CardContent, TextField, Typography } from '@mui/material'
import { Field } from 'formik'

const EmergencyLoanSection = ({values, styles, touched, errors}) => {
    console.log(values.emergencyLoanBalance)

  return (
    <Card Paper elevation={0}  sx={{ border:'1px solid gray'}}>
        <CardContent>
          <Typography variant='h6'>Emergency Loan</Typography>
            <Field
                name="emergencyLoanBalance"
                as={TextField}
                label="Balance"
                value={values.emergencyLoanBalance}
                fullWidth
                required
                margin="normal"
                error={touched.emergencyLoanBalance && Boolean(errors.emergencyLoanBalance)}
                helperText={touched.emergencyLoanBalance && errors.emergencyLoanBalance}
            />
            <Field
                name="emergencyLoanMonthlyRecovery"
                as={TextField}
                label="Monthly Recovery"
                value={values.emergencyLoanMonthlyRecovery}
                fullWidth
                required
                margin="normal"
                error={touched.emergencyLoanMonthlyRecovery && Boolean(errors.emergencyLoanMonthlyRecovery)}
                helperText={touched.emergencyLoanMonthlyRecovery && errors.emergencyLoanMonthlyRecovery}
            />
            <Field
                name="emergencyLoanDate"
                as={TextField}
                margin="normal"
                value={values.emergencyLoanDate}
                type="date"
                fullWidth
                required
              
                error={touched.emergencyLoanDate && Boolean(errors.emergencyLoanDate)}
                helperText={touched.emergencyLoanDate && errors.emergencyLoanDate}
            />
            <Field
                name="EmergencyamountSanctioned"
                as={TextField}
                label="Amount Sanctioned"
                value={values.EmergencyamountSanctioned}
                fullWidth
                required
               margin="normal"
                error={touched.EmergencyamountSanctioned && Boolean(errors.EmergencyamountSanctioned)}
                helperText={touched.EmergencyamountSanctioned && errors.EmergencyamountSanctioned}
            />
    </CardContent>
  </Card>
  )
}

export default EmergencyLoanSection
