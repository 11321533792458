import React from 'react';
import { Typography, Box, Container } from '@mui/material';
import styled from '@emotion/styled';

const LayoutContainer = styled(Container)`  
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content:start;
  max-width: 600px;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  adding: 16px;
  top: 10%;
  left:15%;
  marginTop: theme.spacing(2);
  marginBottom: theme.spacing(2);
  backgroundColor:'#ccffff';
`;

const NotFound = () => {
  return (
    <LayoutContainer>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
        <Typography variant="h3" color="error">
            404
        </Typography>
        <Typography variant="h5">
            Page Not Found
        </Typography>
        <Typography variant="body1" color="textSecondary">
            Sorry, the page you are looking for does not exist.
        </Typography>
        </Box>
    </LayoutContainer>
  );
};

export default NotFound;
