import React from 'react';
import { FieldArray, Field } from 'formik';
// import { useStyles } from '../styles/styles';
// import ReusableTable from './ReusableTable';
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';


import styled from '@emotion/styled';

const BtnStyle=styled(Button)`
        margin:8px; 
        padding:8px;
        border-redius:5px;
`

const TableStyle = styled(Table)`
    // max-width: 650px;
    width:100%;
`


const DebitCreditTable = ({name, title}) => {
    
  return (
    <FieldArray name={name}>
        {({push, remove, form}) =>(
            <>
                <TableStyle >
                    <TableHead>
                    <TableRow>
                     
                        <TableCell>ID</TableCell>
                        <TableCell>Account</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Actions</TableCell>
                     </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            form.values[name].map((item, index) =>(
                                <TableRow key={index}>
                                    <TableCell>{index+1}</TableCell>
                                    <TableCell>
                                        <Field
                                            as={TextField}
                                            name={`${name}.${index}.id`}
                                            fullWidth
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Field
                                            as={TextField}
                                            name={`${name}.${index}.account`}
                                            fullWidth
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Field
                                            as={TextField}
                                            name={`${name}.${index}.amount`}
                                            fullWidth
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <BtnStyle
                                            variant='contained'
                                            color='secondary'
                                            onClick={()=>remove(index)}
                                          
                                        >
                                            Remove
                                        </BtnStyle>
                                    </TableCell>
                                </TableRow>    
                            )) }                            
                    </TableBody>    
                </TableStyle>
                <BtnStyle
                    variant='contained'
                    color='primary'
                    onClick={()=>push({id:'', account:'', amount:''})} 
                    
                >
                    Add {title}
                </BtnStyle>
            </>
        )}
    </FieldArray>  
  )
}

export default DebitCreditTable;
