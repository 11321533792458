import React from 'react';
import './home.scss';
import Navbar from '../Layout/navbar/Navbar';

import {Routes,Route} from 'react-router-dom'
import Members from '../componets/member/membermaster/members';
// import Openmember from '../componets/home/Openmember';
import Account from '../componets/account/Account';
import Setup from './../../src/componets/setup/Setup'
import Recovery from '../componets/recovery/AddRecoveryList';
import Journal from '../componets/journal/journal';
import Payments from '../componets/payments/payments';
import Reports from '../componets/reports/reports';
// import MemberForm from '../componets/home/OpenmemberCompont';
import MemberDataGrid from '../componets/home/OpenMember/MemberDataGrid';
import UserProfile from '../componets/UserProfile/UserProfile';
import User from '../componets/UserProfile/user';
import Bank from '../componets/UserProfile/bank';
import NextedSidebar from '../Layout/sidebar/nextedSideBar';

// import OfficeInformation from '../componets/UserProfile/officeInformation';

import OfficeComponent from '../componets/UserProfile/office';
import SettingsComponents from '../componets/UserProfile/settingsComponents';

import ChangePassword from '../componets/UserProfile/changePassword';
import LoanLegender from '../componets/member/LoanLegender/LoanLegenderComp';
import CreateAccount from './../componets/account/CreateAccount';
import NotFound from './NotFound';
import FinalizeDividend from '../componets/account/divident/finalizeDividend';
import ThriftInterestDividendForm from '../componets/account/ThriftInterestCalculation';
import RaiseIntersetOutStanding from '../componets/account/RaiseIntersetOutStanding';
import CashWithdrawl from '../componets/journal/CashWithdrawl';
import ClearSuspense from '../componets/journal/ClearSuspense';
import DepositedChequeReversal from '../componets/journal/DepositedCheque/DepositedChequeReversal';



const Home = () => {
  return (
    <div className='home'>
        <Navbar/>
        <div className="homeconatiner">
          <NextedSidebar />
            {/* <Header /> */}
            <div className="widget" >
              <Routes>
                  {/* <Route path="/dashboard" element={<Dashboard />}/> */}
                   {/* <Route path="/home/members" element={<Members/>}/> */}
                   <Route path="/dashboard" element={<MemberDataGrid/>}/>
                   {/* <Route path="/dashboard" element={<Openmember/>}/> */}
                   <Route path="/members/*" >
                        <Route index element={<Members/>}/>
                        <Route path="loanledger" element={<LoanLegender/>}/>
                        
                    </Route>
                   <Route path="/account/" >
                      <Route index element={<Account/>}/>
                      <Route path="od" element={<CreateAccount/>}/>
                      <Route path="Finalize_Dividen" element={<FinalizeDividend/>}/>
                      <Route path="Divide" element={<ThriftInterestDividendForm/>}/>
                      <Route path="rise_interest_os" element={<RaiseIntersetOutStanding/>}/>
                  </Route>
                   <Route path="/setup/*" element={<Setup />}/>
                   <Route path="/recovery/*" element={< Recovery />}/>

                   <Route path="/journal/" >
                        <Route index  element={<Journal />} />
                        <Route path="cash_withdrawl" element={<CashWithdrawl />} />
                        <Route path="clear_suspense" element={<ClearSuspense />} />
                        <Route path="depositedchequereversal" element={< DepositedChequeReversal />} />
                   </Route>
                   <Route path="/payments/*" element={< Payments />}/>
                   <Route path="/report/*" element={< Reports />}/>
                   <Route path="/user" element={< User />}/>
                   <Route path="/profile" element={< UserProfile />}/>
                   <Route path="/bank" element={< Bank />}/>
                   <Route path="/setting" element={< SettingsComponents />}/>
                   <Route path="/changepassword" element={<ChangePassword/>}/>             
                   <Route path="/office" element={< OfficeComponent />}/>
                   <Route path="*" element={<NotFound />} />
                 
                  
                  
              </Routes>
            </div>
        </div>

    </div>
  )
}

export default Home;
