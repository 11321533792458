import React from 'react';
import { Card, CardContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { Field } from 'formik'

const NewLoanVoucherSection = ({values, touched, errors, handleChange}) => {
   
  return (
    <Card Paper elevation={0}  sx={{ border:'1px solid gray'}}>
      <CardContent>
        <Typography variant='h6'> New Loan Voucher</Typography>
        <FormControl fullWidth>
            <InputLabel id="loanTypeLabel">Loan Type</InputLabel>
            <Select
                labelId="loanTypeLabel"
                id="loanType"
                name="loanType"
                value={values.loanType}
                onChange={handleChange}
                error={touched.loanType && Boolean(errors.loanType)}
            >
                <MenuItem value="Normal">Normal</MenuItem>
                <MenuItem value="Emergency">Emergency</MenuItem>
            </Select>
        </FormControl>

        <Field
            name="amountSanctioned"
            as={TextField}
            label=" amount Sanctioned"
            value={values.amountSanctioned}
            fullWidth
             margin="normal"
            required     
            error={touched.amountSanctioned&& Boolean(errors.amountSanctioned)}
            helperText={touched.amountSanctioned&& errors.amountSanctioned}
        />
        <Field
            name="shareMoney"
            as={TextField}
            label="Share Money to be Deducted"
            value={values.shareMoney}
            fullWidth
             margin="normal"
            required         
            error={touched.shareMoney&& Boolean(errors.shareMoney)}
            helperText={touched.shareMoney&& errors.shareMoney}
        />
         <Field
            name="reasonForLoan"
            as={TextField}
            label="Reason For Loan"
            value={values.reasonForLoan}
            fullWidth
             margin="normal"
            required        
            error={touched.reasonForLoan && Boolean(errors.reasonForLoan)}
            helperText={touched.reasonForLoan && errors.reasonForLoan}
        />
        <Field
            name="newLoanDate"
            as={TextField}
            label="Monthly Recovery"
            value={values.newLoanDate}
            fullWidth
             margin="normal"
            required          
            error={touched.newLoanDate && Boolean(errors.newLoanDate)}
            helperText={touched.newLoanDate && errors.newLoanDate}
        />
        <Field
            name="newLoanDate"
            as={TextField}
            label="Loan Date"
            value={values.newLoanDate}
            fullWidth
             margin="normal"
            required        
            error={touched.newLoanDate && Boolean(errors.newLoanDate)}
            helperText={touched.newLoanDate && errors.newLoanDate}
        />
      </CardContent>
    </Card>
  )
}

export default NewLoanVoucherSection
