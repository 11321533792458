import React from 'react';
// import Navbar from '../../Layout/navbar/Navbar';
// import  NextedSidebar from './../../Layout/sidebar/nextedSideBar';
import {Routes,Route} from 'react-router-dom'
import CreateBank from './CreateBank';

const Setup = () => {
  return (
    <div className='home'>       
        <div className="homeconatiner">
            {/* <NextedSidebar/> */}
            <div className="widget" >
              <Routes>
                   <Route path="/*" element={<CreateBank />}/>
                   {/* <Route path="/LoanLegender" element={<Recoveries/>}/> */}
              </Routes>
            </div>
        </div>

    </div>
  )
}

export default Setup
