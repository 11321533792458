import { Container, Grid, Paper } from '@mui/material'
import React from 'react'

import { useStyles } from '../../styles/styles'

import ForgotPassword from './../../auth/ForgotPassword'

const UserProfile = () => {
  const styles = useStyles();
  return (
    <Container className={[styles.open_container]} >
        {/* TOP */}
      <Grid sx={{display:'flex', flexDirection:'row', width:'100%',  }}>
         < ForgotPassword />
      </Grid>

       {/* bottom */}

      <Grid item spacing={2} sx={{display:'flex', flexDirection:'row', width:'100%',  justifyContent:'space-evenly', height:'50%' }}>
         
         {/* right */}
        {/* <Grid item spacing={2} sx={{display:'flex', flexDirection:'column', width:'50%', }} >
          <OfficeInfromationComponent/>
        </Grid> */}

         {/* left */}
        {/* <Grid sx={{display:'flex', flexDirection:'column', width:'50%', }}>
          <SettingsComponents/>
        </Grid> */}

      </Grid>
    </Container>
  )
}

export default UserProfile
