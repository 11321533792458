import { Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { Field } from 'formik'
import React from 'react'
import DebitCreditTable from '../../reuseable/DebitCreditTable'

const OtherDeductionsSection = ({values, touched, errors, handleChange}) => {
  return (
    <Card sx={{ border:'1px solid gray'}} Paper elevation={0}>
      <CardContent>
        <Typography variant='h6'>Other Deductions</Typography>
        <FormControl fullWidth>
            <InputLabel id="paymentModeLabel" variant='outlined'>Decutions of</InputLabel>
            <Select
              labelId="paymentModeLabel"
              id="paymentMode"
              margin="normal"

              value={values.paymentMode}
              onChange={handleChange}
              error={touched.paymentMode && Boolean(errors.paymentMode)}
            >
              <MenuItem value="Admission" selected>Admission Fees</MenuItem>
              <MenuItem  value="Cheque">Cheque</MenuItem>
              <MenuItem value="DD">DD</MenuItem>
              <MenuItem value="Cash">Cash</MenuItem>
            </Select>
        </FormControl>
        <Grid container  >
          <Grid sx={{marginLeft:'8px', marginBottom:'8px' }}>
          <Field
              name="normalLoanBalance"
              as={TextField}
              label="Balance"
              value={values.normalLoadBalance}
              fullWidth
               margin="normal"
              required      
              error={touched.normalLoadBalance && Boolean(errors.normalLoadBalance)}
              helperText={touched.normalLoadBalance && errors.normalLoadBalance}
          />
          </Grid>
          <Grid sx={{marginLeft:'8px', marginBottom:'8px'}}>
          <FormControl fullWidth>
              <InputLabel id="paymentModeLabel" >ID</InputLabel>
              <Select
                labelId="paymentModeLabel"
                id="paymentMode"
                fullWidth
                 margin="normal"
                value={values.paymentMode}
                onChange={handleChange}
                error={touched.paymentMode && Boolean(errors.paymentMode)}
              >
                <MenuItem value="Admission" selected>Admission Fees</MenuItem>
                <MenuItem  value="Cheque">Cheque</MenuItem>
                <MenuItem value="DD">DD</MenuItem>
                <MenuItem value="Cash">Cash</MenuItem>
              </Select>
          </FormControl>
          </Grid>
          <Grid sx={{ marginLeft:'8px', marginBottom:'8px' }}>
          <Field
              name="normalLoanBalance"
              as={TextField}
              label="Amount"
              value={values.normalLoadBalance}
               margin="normal"
              fullWidth
              required  
              error={touched.normalLoadBalance && Boolean(errors.normalLoadBalance)}
              helperText={touched.normalLoadBalance && errors.normalLoadBalance}
          />
          </Grid>
        </Grid>
        <Grid  item xs={12}>
          <DebitCreditTable name="debitHeads" title="Debit Heads"  />
        </Grid>
      </CardContent>
    </Card>
  )
}

export default OtherDeductionsSection
