import React from 'react';
import { TextField } from '@mui/material';
import { Field } from 'formik';

const CustomTextField = ({ label, name, ...props }) => (
  <Field name={name}>
    {({ field, form }) => (
      <TextField
        {...field}
        fullWidth
        label={label}
        variant="outlined"
        margin="normal"
        error={form.touched[name] && Boolean(form.errors[name])}
        helperText={form.touched[name] && form.errors[name]}
      />
    )}
  </Field>
);

export default CustomTextField;