import React from 'react';
import { Box, RadioGroup, FormControlLabel, Radio, TextField } from '@mui/material';
import CustomButton from '../../../reuseable/CustomButton';

const SearchCompinent = ({ values, setFieldValue, handleChange, handleSubmit }) => {
  return (
    <Box display="flex" alignItems="center" gap={2} flexWrap="wrap" sx={{ mb: 3, justifyContent: 'space-between' }}>
    <RadioGroup
      row
      value={values.searchType}
      onChange={(e) => setFieldValue('searchType', e.target.value)}
    >
      <FormControlLabel value="onDate" control={<Radio />} label="On Date" />
      <FormControlLabel value="transactionId" control={<Radio />} label="Transaction ID" />
      <FormControlLabel value="voucherNo" control={<Radio />} label="Voucher No" />
      <FormControlLabel value="chequeNo" control={<Radio />} label="Cheque No" />
    </RadioGroup>

    <Box>
      {values.searchType === 'onDate' ? (
        <TextField
        label="Select Date"
        type="date" // Change this to "date"
        value={values.onDate}
        onChange={(e) => setFieldValue('onDate', e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      ) : (
        <TextField
          label={values.searchType.replace(/([A-Z])/g, ' $1').trim()}
          value={values.searchValue}
          onChange={handleChange}
          name="searchValue"
        />
      )}
    </Box>
      
      <CustomButton
           btnLable="Search" type="submit" color="primary"  onClick={handleSubmit}
      />
    
    </Box>
  );
};

export default SearchCompinent;
