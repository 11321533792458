import React, { useEffect, useState } from 'react'
import Table from './../../../reuseable/ReusableTable';
import axios from 'axios';

const LoanLegender = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        axios.get('https://jsonplaceholder.typicode.com/posts')
        .then((response) =>{
            setData(response.data)
            setLoading(false)
        }).catch((error)=>{
            console.log('Error fetaching data:', error)
            setLoading(false)
        })
    },[])

    const columns = [
        {id:'sno', label:'SNO'},
        {id:'transaction-type', label:'Transaction Type'},
        {id:'date', label:'Date'},
        {id:'month', label:'Month'},
        {id:'year', label:'year'},
        {id:'loanId', label: 'LoanId'},
        {id:'type', label:'Type'},
        {id:'completed', label:'Completed'},
        {id:'payment', label:'Payment'},
        {id:'pri.Recd', label:'Pri Recd'},
    ]


  return (
    <div>
        {
            loading ? (
                <p>Loading...</p>
            ):(<Table data={data} columns={columns} />)
        }
    </div>
  )
}

export default LoanLegender
