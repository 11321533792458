import React, {useState} from 'react'
import { Grid, Box, CircularProgress} from '@mui/material';

import styled from '@emotion/styled';
import ReusableTable from './../../reuseable/ReusableTable';


const TableStyle = styled(Box)`
  display:flex;
  // height: 100vh;
  flex-direction:column;
  justify-content:start; 
  margin-bottom:0px;
  padding:8px; 
  background-color:'black'
`


const LoadingStyle = styled(Box)`
    display:flex ;
    justify-content:center; 
    align-items:center;
     height: 100vh;
`;



const RecoveryList = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)

    const handleEditClick = (member) => {
        // setEditingMember(member);
        // setOpenModal(true);
      };


    const columns = [
        {field:'sno', label:'SNO'},
        {field:'Month', label:'Month'},
        {field:'Year', label:'Year'},
        {field:'Principal', label:'Principal'},
        {field:'Thrift', label:'Thrift'},
        {field:'Surety', label:'Surety'},
        {field:'Total', label: 'Total'},
        {field:'ListNo', label:'ListNo'},
        {field:'ListName', label:'ListName'},
    ]


  return (
    <Grid>
        {
          loading ? (
            <LoadingStyle >
              <CircularProgress />
            </LoadingStyle>
          ) :(
            <TableStyle>
              <Box>
                <ReusableTable data={data} columns={columns} onEdit={handleEditClick}/>
              </Box>
            </TableStyle>

          )
        }
    </Grid>
  )
}

export default RecoveryList
