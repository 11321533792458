import React,{useState} from 'react'
import { useNavigate, Link } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import  axios  from 'axios';
import {
  TextField, Button, CircularProgress, Box, InputAdornment, Checkbox, FormControlLabel, Divider,
  Card, CardHeader, CardContent, Grid, IconButton, Snackbar, Alert
} from '@mui/material';
// import { loginSuccess, loginFailed } from '../redux/authSlice';
import {Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
// import './login.scss';
import styled from '@emotion/styled';
// import { useSnackbar } from 'notistack';
// import useErrorHandler from './utils/errorHandler';


import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';


const LayoutContainer = styled(Box)`
 position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  // padding: 16px;
  // background-color:  #8b0ec4;
  top: 3%;
  left: 40%;
`;

const StyledCard = styled(Card)`
  max-width: 400px;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  padding: 24px;
`;

const Header = styled(CardHeader)`
  text-align: center;
  margin-bottom: 16px;
  color: blueviolet;
`;

const FormStyled = styled(Form)`
  width: 100%;
`;

const SubmitBtn = styled(Button)`
  margin-top: 16px;
  background-color: primary;
  color: #fff;
  &:hover {
    background-color: #303f9f;
  }
`;

// const SubmitBtn=styled(Button)`
//  marginTop: theme.spacing(2);
//   backgroundColor: 'primary';
//   color: '#fff';
//   '&:hover': {
//     backgroundColor: '#303f9f';
//   },
//   link: {
//     marginTop: theme.spacing(2);
//     textAlign: 'center';
//   },
// `;





const Login = () => {
    
    // const dispatch=useDispatch()
    // const { enqueueSnackbar } = useSnackbar();
    // const { handleError } = useErrorHandler();

    const navigate = useNavigate();   
    const [checked, setChecked] = useState(true);
    const [error, setError] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');
    const initialValues = {UserName: '', Password: '' };
    

    const validationSchema=Yup.object({
        UserName: Yup.string().min(3).max(255).required('user must be required'),
        Password: Yup.string().min(6,"Password must be at least 6 characters").max(16).required('Password Must be required'),
      })


      const [showPassword, setShowPassword] = useState(false);
    
      const handleClickShowPassword = () => {
                setShowPassword(!showPassword);
        };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    
    const handleSnackbarClose = () => {
      setSnackbarOpen(false);
    };
    
     const handleSubmit = async( values, { setSubmitting, setErrors })=>{
       
        try{
            const response = await axios.post(`https://tecslkoupe.com/Admin/Home/Login`,{
                Password:values.Password,
                UserName:values.UserName,
                IpAddress: "192.168.1.12",
                Source: 1,
                Latitude: "22.22556",
                Longitude: "19.5564"
            },
                {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                }
            );
            const { Token, Profile} = response.data;
            const {Profile:{Name}} = response.data
            if(Token){
                localStorage.setItem('token', Token);
                localStorage.setItem('Name', Name)
                navigate('home')
            }
            // dispatch(loginSuccess({user, token}));
           

        }catch(error){
            // dispatch(loginFailed(error.message));
            // handleError(error);
            setSnackbarMessage(error.response?.data?.message || 'Login failed');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            setErrors({ submit: error.response?.data?.message || 'Login failed' });
            setSubmitting(false);
        }
        
    }
    
  return (
    
    <LayoutContainer >
        <StyledCard >
            <Header   
                title="Enter your credentials to continue"
                titleTypographyProps={{ variant: 'h5', component: 'h2' }}
            />
            <Divider />     
            <CardContent>   
            <Formik 
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}

              >{({ values, isSubmitting, handleChange, handleSubmit, errors, touched }) =>(
                <FormStyled  onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>             
                            <Field 
                                as={TextField}
                                id="UserName"
                                type="text"
                                label="User Name"
                                variant="outlined" 
                                value={values.UserName}           
                                error={touched.UserName && Boolean(errors.UserName)}
                                helperText={touched.UserName && errors.UserName}
                                margin="normal"
                                required
                                fullWidth
                            />
                         </Grid>
                         <Grid item xs={12}>                       
                            <Field 
                                as={TextField}
                                type={showPassword ? 'text' : 'password'}
                                id="Password"
                                label="Password"
                                variant="outlined" 
                                margin="normal"
                                fullWidth
                                value={values.Password}
                                error={touched.Password && Boolean(errors.Password)}
                                helperText={touched.Password && errors.Password}
                                InputProps={{
                                    endAdornment:(
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                          // edge="end"
                                        >
                                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }} 
                             
                                required
                                
                            />
                        </Grid>                    
                    <Grid item xs={12}>
                    <FormControlLabel
                        control={
                        <Checkbox 
                            checked={checked} 
                            onChange={(event) => setChecked(event.target.checked)} 
                            name="checked" 
                            color='primary'
                        />
                       }
                         label="Remember me"
                    /> 

                        <Link to='/forgotPassword' variant="body2">
                            Forgot Password?  
                        </Link>
                    </Grid>
                    <Grid item xs={12}>
                    <SubmitBtn 
                        disabled={isSubmitting} 
                        size="large" 
                        type="submit"
                        variant="contained" 
                        fullWidth
                        
                    >
                    {isSubmitting ? <CircularProgress size={24} /> : 'Login'}
                    </SubmitBtn>
                    </Grid>
                    <Divider />
                        <Grid  item xs={12} className="login-bottom-div">
                                <Link to='/register' variant="body2">
                                    Don't have an account? 
                                 </Link>
                         
                        </Grid >
                    </Grid>  
                </FormStyled>
            )}
            </Formik>
            </CardContent>
        </StyledCard>
        <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </LayoutContainer>
  )
}

export default Login
