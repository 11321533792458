import React from 'react';
import { Grid, TextField, Button } from '@mui/material';
import { Field } from 'formik';
import styled from '@emotion/styled';

// display: flex;
//   width: 100%;
//   flex-direction:row;
//   justify-content:space-between;
//   align-items: center;
//   flex-wrap: nowrap;
//    margin:8px;
//   // marginTop: theme.spacing(2);
//   // marginBottom: theme.spacing(2);
//   backgroundColor:'#ccffff';


const MainContainer = styled(Grid)` 
  display: flex;
  flex-direction:column;
  width:100%;
  
 
`;

const FeildContainer = styled(Grid)`
  display: flex;
  flex:1;
  justify-content:start;
  align-items: center;
  width:100%;
  flex-wrap: wrap;
  
 `
const BtnContainer = styled(Grid)`
  display: flex;
  flex:1;
  justify-content:space-around;
  align-items: center;
  width:100%; 
  
`


const ThriftDividendInput = ({ values, handleChange }) => {
    
  return (
    <MainContainer container spacing={2}>
      <FeildContainer item xs={6} spacing={2}> 
        <Grid >
          
        <Field
            as={TextField}
            name="thriftPercentage"
            label="Thrift %"
            value={values.thriftPercentage}
            onChange={handleChange}
            fullWidth
            margin="normal"   
                 
        />
        </Grid>
         <Grid>

         <Field
          as={TextField}
          fullWidth
          name="dividendPercentage"
          label="Dividend %"
          value={values.dividendPercentage}
          onChange={handleChange}
           margin="normal"
           sx={{paddingRight:'16px'}}
          
        />
         </Grid>
      </FeildContainer>
      <BtnContainer item xs={6}  >
            <Button variant="contained" color="primary"  >Calculate</Button>
            <Button variant="contained" color="warning"  >Delete Selected Financial Year Data</Button>    
      </BtnContainer>
    </MainContainer>
  );
};

export default ThriftDividendInput;
