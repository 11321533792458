import React from 'react'
import { TextField , Box, MenuItem, Typography, Grid, Paper} from '@mui/material';
import {  Field, ErrorMessage } from 'formik';


const PersonalDetails=({ values})=>{
    return(
    <Box sx={{margin: '8px', padding:'8px'}} component={Paper} elevation={3}>
        <Typography variant="h6" component="h3" sx={{  display:'flex', justifyContent:'start', color:'#0b0e30' }} >
         Personal Details 
      </Typography>
      <Grid container spacing={2}>
      <Grid item xs={6}>
      <Field 
                            id='title' 
                            as={TextField}
                            label="Title"
                            select
                            variant="outlined" 
                            required
                            defaultValue="Mr."
                            margin="normal"                          
                          
                            helperText={<ErrorMessage name="title" />}
                        >   
                            <MenuItem value="Mr.">Mr.</MenuItem>
                            <MenuItem value="Ms.">Ms.</MenuItem>
                            <MenuItem value="Mrs.">Mrs.</MenuItem>
                        </Field>    
                        <Field 
                            id="firstName" 
                            as={TextField} 
                            type="text"
                            label="First Name" 
                            value={values.firstName}
                            variant="outlined" 
                            helperText={<ErrorMessage name="firstName" />}
                            required
                            margin="normal"           
                           fullWidth
                        />
                        <Field 
                            name="lastName"  
                            as={TextField} 
                            label="Last Name" 
                            type="text"
                            value={values.lastName}
                            variant="outlined" 
                            helperText={<ErrorMessage name="lastName" />}
                            required
                            fullWidth     
                            
                        />
                        <Field 
                            name="designation" 
                            as={TextField} 
                            label="Designation" 
                            variant="outlined" 
                            value={values.designation}
                            type="text"
                            helperText={<ErrorMessage name="designation" />}
                            required
                            margin="normal"          
                            fullWidth
                            />
        </Grid>
      
      <Grid item xs={6}>
        
      <Field 
                            name="fatherName" 
                            as={TextField} 
                            label="Father's Name" 
                            variant="outlined"
                            value={values.fatherName} 
                            type="text"
                            helperText={<ErrorMessage name="fathername" />}
                            required
                            margin="normal"         
                            fullWidth
                            />
                        <Field 
                            name="bankName" 
                            as={TextField} 
                            label="Bank Name" 
                            variant="outlined"
                            type="text" 
                            helperText={<ErrorMessage name="bankName" />}
                            required
                            margin="normal" 
                            fullWidth
                        />
                        <Field 
                            name="bankAcNo" 
                            as={TextField} 
                            label="Bank Account Number" 
                            variant="outlined"
                            type="text"
                            required
                             margin="normal"
                             fullWidth
                            helperText={<ErrorMessage name="bankAcNo" />}
                        />
                        <Field 
                            name="fund" 
                            as={TextField} 
                            label="Fund" 
                            type="number" 
                            variant="outlined" 
                            helperText={<ErrorMessage name="fund" />}
                            required
                            margin="normal"          
                            fullWidth
                        />
        </Grid>
      </Grid>
                    
    </Box>)
}

export default PersonalDetails; 