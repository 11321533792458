import { Card, CardContent, FormControl, InputLabel, MenuItem, Select,TextField, Typography } from '@mui/material'
import { Field } from 'formik'
import React from 'react'

const PaymentDetailsSection = ({values,  touched, errors, handleChange}) => {
  return (
    <Card sx={{ border:'1px solid gray'}} Paper elevation={0}>
      <CardContent>
        <Typography variant='h6' >Payment Details</Typography>
        <FormControl fullWidth margin='normal'>
            <InputLabel id="paymentModeLabel">Mode</InputLabel>
            <Select
              labelId="paymentModeLabel"
              id="paymentMode"
              value={values.paymentMode}
              onChange={handleChange}
              error={touched.paymentMode && Boolean(errors.paymentMode)}
            >
              <MenuItem>None</MenuItem>
              <MenuItem>Cheque</MenuItem>
              <MenuItem>DD</MenuItem>
              <MenuItem>Cash</MenuItem>
            </Select>
        </FormControl>
        <Field
            name=" chequeNumber"
            as={TextField}
            label="chequeNo/DD"
            value={values.chequeNumber}
            fullWidth
            required
           margin='normal'
            error={touched.chequeNumber&& Boolean(errors.chequeNumber)}
            helperText={touched.chequeNumber && errors.chequeNumber}
        />
         <FormControl fullWidth  margin='normal'>
          <InputLabel id=" bankName">Bank</InputLabel>
          <Select
            labelId="bankName"
            id="bankName"
            name="bankName"
            value={values.bankName}
            onChange={handleChange}
            error={touched.bankName && Boolean(errors.bankName)}
          >
            <MenuItem value="SBI">SBI</MenuItem>
            <MenuItem value="BOB">BOB</MenuItem>
            <MenuItem value="ICICI">ICICI</MenuItem>
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  )
}

export default PaymentDetailsSection
