import React from 'react';
import { Grid, TextField } from '@mui/material';
import { Field, ErrorMessage } from 'formik';
import styled from '@emotion/styled';

const MainContainer = styled(Grid)`  
  display: flex;
  width: 100%;
  flex-direction:row;
  justify-content:space-between;
  align-Item:center;
  flex-wrap: nowrap;
  
`;

const FeildContainer = styled(Grid)`
  display: flex;
  width: 40%;
  flex:1;
  flex-direction: column;
 `
const BtnContainer = styled(Grid)`
   display: flex;
   flex-wrap: wrap;
   justify-content: space-around;
   width: 40%;
   flex:1;
  flex-direction: column;
  margin:8px;
`

const ShareDetails = ({values}) => {
  return (
    <MainContainer container spacing={2}>
          <Grid item xs={6}>
            {[ { name: "ShareOpening", label: "Share Opening" },
               { name: "ShareCollection", label: "Share Collection" },
                { name: "SharePaidBefore", label: "Share Paid befor 30 Sep" },
                { name: "SharePaidAfter", label: "Share Paid befor 30 Sep" },
                { name: "Dividen", label: "Dividen" },
            ].map(({ name, label, type = "text", component }) => (
                <Grid item key={name} xs={12}>
                    {component ? component : (
                        <Field
                            as={TextField}
                            name={name}
                            label={label}
                            type={type}
                            value={values[name]}
                            fullWidth
                            margin="normal"
                          
                            InputLabelProps={type === "date" ? { shrink: true } : {}}
                        />
                    )}
                    <ErrorMessage name={name} component="div" className="error" />
                </Grid>))
            }
          </Grid>
          
          <Grid item xs={6}>
            {[ { name: "TFOpening", label: "TF Opening" },
               { name: "TFCollection", label: "TF Collection" },
                { name: "TFRefunded", label: "TF Refunded" },
                { name: "TFInterset", label: "TF Interset" },    
            ].map(({ name, label, type = "text", component }) => (
                <Grid item key={name} xs={12}>
                    {component ? component : (
                        <Field
                            as={TextField}
                            name={name}
                            label={label}
                            type={type}
                            value={values[name]}
                            fullWidth
                            margin="normal"
                            
                            InputLabelProps={type === "date" ? { shrink: true } : {}}
                        />
                    )}
                    <ErrorMessage name={name} component="div" className="error" />
                </Grid>))
            }
          </Grid>
    </MainContainer>
  )
}

export default ShareDetails
