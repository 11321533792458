import React from 'react';
// import Navbar from '../../Layout/navbar/Navbar';
// import Sidebar from '../../Layout/sidebar/Sidebar';
import {Routes,Route} from 'react-router-dom'
// import NewLoan from './newLoan';
// import NormalLoanSection from './NormalLoanSection';
import LoanForm from './LoanForm';

const Payments = () => {
  return (
    <div className='home'>
        <div className="homeconatiner">
            <div className="widget" >
              <Routes>
                   <Route path="/*" element={<LoanForm/>}/>
                   {/* <Route path="/LoanLegender" element={<Recoveries/>}/> */}
              </Routes>
            </div>
        </div>

    </div>
  )
}

export default Payments
