import React from 'react';
import { Grid, TextField,Button ,FormControl, RadioGroup, FormControlLabel, Radio, Select, MenuItem, InputLabel } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import styled from '@emotion/styled';

const MainContainer = styled(Grid)`  
  display: flex;
  width: 100%;
  flex-direction:row;
  justify-content:space-between;
  align-Item:center;
  flex-wrap: nowrap;
   margin:8px;
  // marginTop: theme.spacing(2);
  // marginBottom: theme.spacing(2);
  backgroundColor:'#ccffff';
`;

const FeildContainer = styled(Grid)`
  display: flex;
  width: 40%;
  flex:1;
  flex-direction: column;
 `
const BtnContainer = styled(Grid)`
   display: flex;
   flex-wrap: wrap;
   justify-content: space-around;
   width: 40%;
   flex:1;
  flex-direction: column;
  margin:8px;
`


const MemberDetails = ( { values, handleChange }) => {
 
  
  return (
    <MainContainer container spacing={2}>
      <Grid item xs={4}>
        <FormControl>
          <RadioGroup name="memberType" value={values.memberType} onChange={handleChange}>
            <FormControlLabel value="memberID" control={<Radio />} label="Member ID" />
            <FormControlLabel value="location" control={<Radio />} label="Location" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel id="unit">Unit</InputLabel>
          <Select
            labelId="unit"
            name="unit"
            label="Unit"
            value={values.unit}
            onChange={handleChange}
          >
            <MenuItem value="ALLAHABAD">ALLAHABAD</MenuItem>
         
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControlLabel
          control={<Radio />}
          label="Resigned Members"
          name="resignedMembers"
          value={values.resignedMembers}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={4}>
        <Button variant="outlined" size="medium" >Print</Button>
      </Grid>
    </MainContainer>
  );
};

export default MemberDetails;
